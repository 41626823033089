    .months .selMonth {
        width:15vmin;
        /* overflow:hidden; */
        scrollbar-width: none;
        height: 5vh;
        text-wrap: nowrap;
        color: black;
        min-width: 12rem;
    }
    .recuerdaTitle{
      font-weight:600;
      font-size:1.3rem;
      line-height: 1.2;
    }
    .recuerdaTitleBlock{
      font-weight:600;
      font-size:1.5rem;
      line-height: 1.0;
    }
    .recuerdaTitle_Start{
      
      font-size:1.3rem;
      line-height: 1.2;
    }
.colorText_Modal{
  color:#9DACBD
}
    .up_index{
      z-index: 30000 !important
    }
    .months .selMonth .noactive{
     display:none;
    }
    

    .months .selMonth .active {
        background-color: white;
        border-radius: 6px;
        color: #394F65;
       
    }
    .paddingGrowTeacher {
      padding:2%;
      
  }


    @media (max-width: 968px)  {

      .img-fluid{
        width: 4rem !important;
        height: 4rem !important;
        border-radius:20px;
      }
.anchoPaso1_respon{
  max-width: 5rem;
   line-height: 110%
}
      .letraPequeñaCardTitleBook{
        font-size: 1rem !important
    }
      .input-select-step7 select{
  
        background-image: URL("../../assets/assets/arrowdownsel.svg");
        background-size: 1.5rem;
        background-repeat: no-repeat;
        background-position-x:100%;
        background-position-y:center;
        outline: none;
        -webkit-appearance: none;
      
    }


.hourOption{
  background-color: transparent;
  border: none;
  color:var(--elevvaTextTitle);
  min-width: 8rem !important
}

.no_reservar_error{
  font-weight: 700;
  
  width: 8rem;
  font-size: 0.9rem;
  color: #CB3D82;
  text-transform: uppercase;
}

      .trashIcon_res{
        color:#9EACED;
        font-size: 1.5rem
      }
      .titleOneBook{
        font-size: 1.0rem;
        color:var(--elevvaTextTitle);
      }

      .rounded-circle.active {
        background-color: #cb3d82 !important;
        color: #fff!important;
      } 
      .months {
     
        background: #F1F6F9;
       width:100%;
        display: flex;
       
          font-size:1.3vw !important;
          justify-content: space-between;
          vertical-align:middle;
         text-align:center !important;
      
          text-transform: capitalize;
          font-weight: bold;
          cursor: pointer;
      }
  

      .paddingGrowTeacher {
       min-height:6rem;
        
    }
     

      .classTeachers{
        display:flex;
        gap:1rem;
       flex-direction:column;
        
      }
      .ubication_arrow_title{
        top: 15%; position: absolute; left: 5%
      }
      .text_arrow_top{
        color: #394F65;
        font-size: 1rem
      }
      .textTeacherOne {
        text-align: center;
              color: #394F65;
              font-weight: 400;
              font-size: 1rem;
              white-space: nowrap;
      }

      .months .left, .months .right {
        cursor:pointer;
        font-size:2rem;
        margin-left:0 !important;
        margin-right:0 !important;
        justify-content: center;
        color:#9EACED !important;
        
    }
       .months div {
        display: flex;
        align-items: center;
    
        width:100% !important;
    }

    .months .selMonth div {
      display: inline-flex;
      width:100% !important;
      font-size:1rem !important;
      justify-content: center;
      
     text-align:center !important;
    
      text-transform: capitalize;
    
      cursor: pointer;
    }

    .days .selDays span {
      display: inline-flex;
      margin:auto;
         align-items: center;
         justify-content: center;
         text-transform: capitalize;
         color: #9DACBD;
         cursor: pointer;
             width: 9vmin ;
             min-width:16px;
             height: 9vmin ;    
     font-size:1rem;       
  }
  .days .selDays {
    white-space: normal!important;
    text-align: center!important;
    padding:0% 5%;
 
}

    }
    @media (min-width: 969px)  {
     

      .anchoPaso1_respon{
        max-width: 10vmin;
         line-height: 110%
      }

      .letraPequeñaCardTitleBook{
        font-size: 2.3vmin !important
    }

      .input-select-step7 select{
  
        background-image: URL("../../assets/assets/arrowdownsel.svg");
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position-x:100%;
        background-position-y:center;
        outline: none;
        -webkit-appearance: none;
      
    }

      .hourOption{
        background-color: transparent;
        border: none;
        min-width: 8rem
      }

      .no_reservar_error{
        font-weight: 700;
        margin-top: 1.0vh;
        width: 20vw;
        font-size: 0.8vw;
        color: #CB3D82;
        text-transform: uppercase;
      }

      .titleOneBook{
        font-size:0.9rem;
        color:var(--elevvaTextTitle);
      }

      .trashIcon_res{
        font-size: 3.5vmin
      }

      .rounded-circle.active {
        background-color: #cb3d82;
        color: #fff!important;
      } 
      .days .selDays {
        white-space: normal!important;
        text-align: left!important;
        padding: 1% 10%;
    }

      .months {
        border-radius: 20px;
        background: #F1F6F9;
       
        display: flex;
        margin: auto 6%;
          font-size:1.0rem !important;
          justify-content: space-between;
          vertical-align:middle;
         text-align:center !important;
      
          text-transform: capitalize;
          font-weight: bold;
          cursor: pointer;
      }

      .days .selDays span {
        display: inline-flex;
     margin:auto;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        color: #9DACBD;
        cursor: pointer;
            width: 4.5vmin ;
            min-width:16px;
            height: 4.5vmin ;    
    font-size:calc(0.7rem + 0.5vmin);       
    }

      .months .selMonth div {
        display: inline-flex;
        width:100% !important;
        font-size:1vw !important;
        justify-content: center;
        
       text-align:center !important;
      
        text-transform: capitalize;
        font-weight: bold;
        cursor: pointer;
      }

      .months .left, .months .right {
        cursor:pointer;
        font-size:1.5vw;
        width: 1vw;
        margin-left:0 !important;
        margin-right:0 !important;
        justify-content: center;
        color:#9EACED !important;
        
    }
       .months div {
        display: flex;
        align-items: center;
    
        width:13vmin !important;
    }
  
      .img-fluid{
        width: 4rem !important;
        height: 4rem !important;
        border-radius:20px;
      
      }

      .textTeacherOne {
        text-align: center;
              color: #394F65;
              font-weight: bold;
              font-size: 1.7vmin;
              white-space: nowrap;
      }

      .classTeachers{
      display:grid;
        grid-template-columns: repeat(2,1fr);
        column-gap: 1%;
        row-gap: 4%;
      
    }
      .text_arrow_top{
        color: #394F65;
        font-size: calc(0.7rem + 0.3vw)
      }
      .ubication_arrow_title{
        top: 11%; position: absolute; left: 18%
      }
     
    }

 

    
  

    .lineacenterhora{
      position:relative;
      display:inline-flex;
      margin-top:24px;
      width:19vw;
     height:1px;
     margin:2vh 0  !important;
   z-index:999;
     opacity:0.4;
     text-align:center !important;
      
  }


  .colorcajadias{
      background-color:#E5E5E5;
    
  }

  
  



.days .selDays {
  white-space: nowrap;
  text-align: center;
}
.days {
  overflow-x: hidden;
 margin:auto;
  align-items: center;
 
 
}


  
 


.months .selMonth .active {
  background-color: #F1F6F9;
  border-radius: 6px;
  color: #394F65;
  text-wrap: nowrap;
 
}



select.widthHeight::-webkit-scrollbar {
  width: 12px;
  
}
select.styleScroll::-webkit-scrollbar-thumb {

  background-color: #DFDFDF ;
  border-radius:20px;
}

