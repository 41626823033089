
#selectActivity, #dateControlDash{
    font-size:calc(0.4rem + 0.4vw) !important; 
}
.icon-sesion-dash{
    width:calc(2rem + 1vmin);
}

#inputPadreTu input[type="date"]::-webkit-calendar-picker-indicator  {
    display:none !important;
}
#coverflowSlider .swiper-slide-prev,#coverflowSlider .swiper-slide-next{
    background:#FBEDEA !important;
    border-radius:20px !important;
    box-shadow: 0px 10px 20px #707070;
    filter: blur(1px);
}
#coverflowSlider .swiper-slide-duplicate-prev,#coverflowSlider .swiper-slide-duplicate-next,#coverflowSlider .swiper-slide-duplicate-active{
    background:#FFFF !important;
    color:#FFFF !important;
}
#coverflowSlider .swiper-slide-duplicate-prev img,#coverflowSlider .swiper-slide-duplicate-next img ,#coverflowSlider .swiper-slide-duplicate-active img{
   display:none 
}
#coverflowSlider{
    margin:2rem 0;
}
#coverflowSlider .swiper-slide-active{
    background:#ffff !important;
    box-shadow: 0px 10px 20px #707070;
    border-radius:20px !important;
 
}
  


@media (max-width: 992px)  {

    .buttonIndividualTypesCom{
        border:2px solid #F4F0ED;
        background:#F4F0ED;
       border-radius:20px;
       font-size:0.6rem;
       color:#394F65;
       padding:1.5% 3.5%;
       width:100%;
      
       margin:2%;
       
    }
/* //////circle///////// */
.skill{
    width:10rem;
    height:10rem;
   
    position:relative;
}
.outer{
    width:10rem;
    height:10rem;
    border-radius:50%;
    padding:10%;
    box-shadow:6px 6px 10px -1px rgba(0,0,0,0.15), -6px -6px 10px -1px rgba(255,255,255,0.7)
}
.inner{
    width:100%;
    height:100%;
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-items:center;
    box-shadow:inset 4px 4px 6px -1px rgba(0,0,0,0.2),
    inset -4px -4px 6px -1px rgba(255,255,255,0.7),
    -0.5px -0.5px 0px rgba(255,255,255,1),
    0.5px 0.5px 0px rgba(0,0,0,0.15),
    0px 12px 10px rgba(0,0,0,0.05)
}
#number{
    font-weight:600;
    color:#555;

}
#numberActivity{
    font-weight:600;
    color:#555;

}
#numberActivity_mobile{
    font-weight:600;
    color:#555;

}
.progress-bar {
    background-color:var(--barProgress) !important;
   
}
#circleRound{
    fill:none;
    stroke:url(#GradientColor);
    stroke-width:10%;
    stroke-dasharray:290%;
    stroke-dashoffset:0%;
    animation:anim 2s linear forwards;
}
#svgt{
position: absolute;

top:0;
left:0;
}

/* ////// */

    .letraBodyInside{
        font-size: 0.5rem !important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    }

.image_bascula{
    width: 1rem;
}
    .letraBody{
        font-size: 0.7rem !important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    }

    .paddingNav_list{
        padding-top:1rem !important ;
        padding-bottom:1rem !important ;
    }

    .padding_Tension_Container{
        padding:3%

    }
    .padding_perfil_Container{
        padding:0%

    }

    .inputTensionArterial::placeholder{
        text-align:center;
      
        color: var(--elevvaTextTitle);
      
        font-weight:bold;
        }
        .inputTensionArterial1::placeholder{
            text-align:center;
          
            color: var(--elevvaTextTitle);
          
            font-weight:bold;
            }
        
        .inputTensionArterial{
            text-align:center;
            color: var(--elevvaTextTitle);
          
            max-width: 4rem;
            font-weight:bold;
            border:none;
        }
        .inputTensionArterial1{
            text-align:center;
            color: var(--elevvaTextTitle);
          
            max-width: 3rem;
            font-weight:bold;
            border:none;
        }

.addZindex{
    z-index:0 !important;
}
.addZindexMessage{
    z-index:300 !important;
}

    .Iconresponsive{
        font-size: 1.5rem !important
    }

    .tutorial_styles{
        position:fixed;
        color: var(--white);
        width: 60px;
        height: 60px;
        justify-content: center;
        bottom: 6rem;
        right: 1.5rem;
        align-items: center;
        z-index: 10;
        background: var(--elevva);
        border-radius: 50%;
        border: 1px solid var(--elevva);
    }
    .tutorial_styles_mobile{
        position:fixed;
        color: var(--white);
        width: 60px;
        height: 60px;
        justify-content: center;
        bottom: 1.2rem;
        right: 6.7rem;
        align-items: center;
        z-index: 10;
        background: var(--elevva);
        border-radius: 50%;
        border: 1px solid var(--elevva);
    }

    .tutorial_styles_mobile_app{
        position:fixed;
        color: var(--white);
        width: 60px;
        height: 60px;
        justify-content: center;
        bottom: 1.2rem;
        right: 1.2rem;
        align-items: center;
        z-index: 10;
        background: var(--elevva);
        border-radius: 50%;
        border: 1px solid var(--elevva);
    }

    .calendar_Mini{
        width: 1.2rem; height: 1.2rem
    }
    .letraPequeñaCardTitle{
        font-size:1rem !important
    }
    .circleHourglas{
        background:var(--backgroundOnboardingObjective);
        width: auto;
        height: auto;
        line-height:85%;
        align-items: center;
        max-width:100%;
        display: flex;
        padding:0.3rem 1rem;
        text-align:center;
        justify-content: center;
        color: var(--white);
        border-radius: 20px
    }

    .ver_programa_button{
        color: var(--white);
        padding: 2.5% 1%;
        justify-content: center;

        align-items: center;

        background: var(--elevva);
        border-radius: 20px;
        border: 1px solid var(--elevva);
        font-size: 0.9rem;
    }

    .buttonProximas{
        color: var(--white);
        padding: 3% 7%;
        justify-content: center;
  
        align-items: center;
  
        background: var(--elevva);
        border-radius: 20px;
        border: 1px solid var(--elevva);
        font-size: 1rem;
    }
    .letraPequeñaotherDashboard{
        font-size: 0.9rem !important
    }
    .trainerDays_texto{
        font-size: 1rem;

        flex-direction: column;
  
        color: var(--elevvaTextTitle)
    }
    .trainerDays {
    
        background: #F9F9F9;
        padding: 2vmin
    }


    .mobileDashboard{
        background-color:#F9F9F9;
        background-image:url("../../assets/assets/mascarillaMobile.png");
        background-size:contain;
        padding-top:3%;
    }
    .paddingLevelText{
         padding: 0 3%
    }
    .letraPequeña{
        font-size: 1rem!important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    } 
    .letraPequeñaWhite{
        font-size: 0.8rem!important;
        color:var(--white);
        font-weight:500;
    } 
    .letraPequeñaTension_arterial{
        font-size: 1.3rem!important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    } 
    .letraName{
        font-size:1.5rem;
    
        text-align:left;
        flex-direction: column;
        justify-content: center;
        line-height: 1;
        color: var(--elevvaTextTitle)
      }
      .imageCustomer{
        width: 4rem;
        height: 4rem;
        border: 1px solid var(--elevvaArrowColor);
        object-fit: cover;
      }
      .buttonVerPlan{
        
        color: var(--white);
        padding: 8% 9%;
        justify-content: center;

        align-items: center;

        background: var(--elevva);
        border-radius: 20px;
        border: 1px solid var(--elevva);
        font-size: 0.8rem;
     
 }

 .guardarTension{
    color: var(--white);

    padding: 2% 8%;

    background: var(--elevva);
    border-radius: 20px;
    border: 1px solid var(--elevva);
    font-size: 0.8rem;
 }

 .letrapequeñaProfile{
    font-size:1.3rem;
    color:var(--elevvaTexttitleLow);
    text-align:center
 }
 .letrapequeñaProfileDown_other{
    font-size:0.8rem!important;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    color:var(--elevvaTextTitle);
    text-align:center;
   
 }
    .puntosPadre{
       display:flex;
       width:85%;
            padding: 1vmin;
            justify-content: center;
            margin-left:auto ;
          
    }
    .trainerDays_days{
        text-transform: capitalize;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
    }
    .heartIngresar{
        width: 4rem; height: 4rem
    }
    .pTextProx{
        font-size:1rem;
        margin:auto;
    }
    .scrollPlan_proximas{
        align-items:center;
        height:5rem;
        justify-content:stretch;
        }

        .ver_mas{
            color: var(--white);
            padding:3% 7%;
            justify-content: center;
    
            align-items: center;
    
            background: var(--elevva);
            border-radius: 30px;
            border: 1px solid var(--elevva);
            font-size: 1rem;
        }
        .letraPkStatus{
            font-size:0.8rem !important
        }
}
@media (min-width: 1350px)  {
    .circleHourglas{
        background:var(--backgroundOnboardingObjective);
      width: auto;
      height: auto;
      line-height:calc(55%);
      align-items: left;
      width:6rem;
      display: flex;
      padding:calc(0.5vmin + 0.1rem);
      text-align:left;
      justify-content: center;
      color: var(--white);
      border-radius: 20px
    }
    .trainerDays_days_activity {
        text-transform: capitalize;
        width: 1.6rem;
        height: 1.6rem;
      font-size: calc(0.3rem + 0.3vw) !important;;
    }  
}
@media (max-width: 992px)  {
    .trainerDays_days_activity {
        text-transform: capitalize;
        width: 2rem;
        height: 2rem;
      font-size: calc(0.8rem + 0.3vw) !important;;
    }  
   
}
@media (min-width: 993px) and (max-width: 1350px) {
    .circleHourglas{
        background:var(--backgroundOnboardingObjective);
      width: auto;
      height: auto;
      line-height:calc(45%);
      align-items: left;
      width:4rem;
      display: flex;
      padding:calc(0.5vmin + 0.1rem) calc(0.7vmin + 0.1rem);
      text-align:left;
      justify-content: center;
      color: var(--white);
      border-radius: 20px
    }

    .trainerDays_days_activity {
        text-transform: capitalize;
        width: 1.3rem;
        height: 1.3rem;
      font-size: calc(0.3rem + 0.3vw) !important;;
    }  
}
@media (min-width: 993px)  {
  

    .buttonIndividualTypesCom{
        border:2px solid #F4F0ED;
        background:#F4F0ED;
       border-radius:20px;
       font-size:0.7rem;
       color:#394F65;
       padding:1% 3.5%;
       width:100%;
       min-width:10rem;
       margin: 1% auto;
       
    }

  /* //////circle///////// */
.skill{
    width:11.5vmin;
    height:11.5vmin;
   
    position:relative;
}
.outer{
    width:11.5vmin;
    height:11.5vmin;
    border-radius:50%;
    padding:10%;
    box-shadow:6px 6px 10px -1px rgba(0,0,0,0.15), -6px -6px 10px -1px rgba(255,255,255,0.7)
}
.inner{
    width:100%;
    height:100%;
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-items:center;
    box-shadow:inset 4px 4px 6px -1px rgba(0,0,0,0.2),
    inset -4px -4px 6px -1px rgba(255,255,255,0.7),
    -0.5px -0.5px 0px rgba(255,255,255,1),
    0.5px 0.5px 0px rgba(0,0,0,0.15),
    0px 12px 10px rgba(0,0,0,0.05)
}
#numberActivity{
    font-weight:600;
    color:#555;

}
#number{
    font-weight:600;
    color:#555;

}
#numberActivity_mobile{
    font-weight:600;
    color:#555;

}

.progress-bar {
    background-color:var(--barProgress) !important;
   
}
#circleRound{
    fill:none;
    stroke:url(#GradientColor);
    stroke-width:10%;
    stroke-dasharray:290%;
    stroke-dashoffset:0%;
    animation:anim 2s linear forwards;
}
#svgt{
position: absolute;

top:0;
left:0;
}

/* ////// */

    .letraBodyInside{
        font-size: 1.3vmin !important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    }

    .image_bascula{
        width:2.5vmin;
    }

    .letraBody{
        font-size: 1.4vmin !important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    }
    .paddingNav_list{
        padding-top:calc(0.7rem + 0.3vmin) !important ;
        padding-bottom:calc(0.7rem + 0.3vmin) !important ;
            }
    .padding_Tension_Container{
        padding:1%

    }
    .padding_perfil_Container{
        padding:1%

    }
    .inputTensionArterial::placeholder{
        text-align:center;
        align-items:left;
        color: var(--elevvaTextTitle);
        font-size: 2vmin;
        font-weight:bold;
        }
        .inputTensionArterial1::placeholder{
            text-align:center;
            align-items:left;
            color: var(--elevvaTextTitle);
            font-size: 2vmin;
            font-weight:bold;
            }
        
        .inputTensionArterial{
            text-align:center;
            color: var(--elevvaTextTitle);
            font-size: 2.5vmin;
            max-width: 5.5vmin;
            font-weight:bold;
            border:none;
        }
        .inputTensionArterial1{
            text-align:center;
            color: var(--elevvaTextTitle);
            font-size: 2.5vmin;
            max-width: 5.5vmin;
            font-weight:bold;
            border:none;
        }

    .Iconresponsive{
        font-size: 2.4vmin !important;
        width:2.4vmin !important;
    }
    .tutorial_styles{
        position:absolute;
        color: var(--white);
        width: 60px;
        height: 60px;
        justify-content: center;
        bottom: 6rem;
        right: 1.5rem;
        align-items: center;
        z-index: 10;
        background: var(--elevva);
        border-radius: 50%;
        border: 1px solid var(--elevva);
    }
    .calendar_Mini{
        width: 2.5vmin; height: 2.5vmin
    }
    .letraPequeñaCardTitle{
        font-size: 2.6vmin !important
    }
    .letraPkStatus{
        font-size: 1vmin !important;
        font-weight:400 !important;
    }
   

     .ver_mas{
        color: var(--white);
        padding:1.5% 7%;
        justify-content: center;

        align-items: center;

        background: var(--elevva);
        border-radius: 20px;
        border: 1px solid var(--elevva);
        font-size: 1.4vmin;
    }
    .ver_programa_button{
        color: var(--white);
        padding: 1% 3%;
        justify-content: center;

        align-items: center;

        background: var(--elevva);
        border-radius: 20px;
        border: 1px solid var(--elevva);
        font-size: 1.4vmin;
    }
    .scrollPlan_proximas{
    align-items:center;
    height:10vmin;
    justify-content:stretch;
    }

    .pTextProx{
        font-size:1.7vmin;
        margin:auto;
    }
    .buttonProximas{
        color: var(--white);
        padding: 1.5% 7%;
        justify-content: center;
  
        align-items: center;
  
        background: var(--elevva);
        border-radius: 20px;
        border: 1px solid var(--elevva);
        font-size: 1.4vmin;
    }

    .heartIngresar{
        width: 7.5vmin; height: 7.5vmin
    }

    
 .guardarTension{
    color: var(--white);

    padding: 3% 10%;

    background: var(--elevva);
    border-radius: 20px;
    border: 1px solid var(--elevva);
    font-size: 1.4vmin;
 }
    .letraPequeñaotherDashboard{
        font-size: 1.6vmin !important
    }
    .trainerDays_days{
        text-transform: capitalize;
        width: 3.7vmin;
        height: 3.7vmin;
        font-size: 1.4vmin;
    }
    .trainerDays {
        background: var(--clasesPending);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .trainerDays_texto{
        font-size: 1.8vmin;

        flex-direction: column;
  
        color: var(--elevvaTextTitle)
    }
    .puntosPadre{
        display:flex;
             padding: 1vmin;
             justify-content: center;
             margin: 1vmin 0 ;
           
     }
    .letrapequeñaProfile{
        font-size:2.3vmin;
        color:var(--elevvaTexttitleLow);
        text-align:center
    }
    .letrapequeñaProfileDown_other{
        font-size:1.0vmin !important;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        color:var(--elevvaTextTitle);
        text-align:center;
       
    }

    .buttonVerPlan{
        
            color: var(--white);
            padding: 8% 9%;
            justify-content: center;
    
            align-items: center;
    
            background: var(--elevva);
            border-radius: 20px;
            border: 1px solid var(--elevva);
            font-size: 1.4vmin;
         
    }
    .paddingLevelText{
        padding: 0% 15%
   }

    .imageCustomer{
        width: 7vmin;
        height: 7vmin;
        border: 1px solid var(--elevvaArrowColor);
        object-fit: cover;
        
      }

    .letraName{
        font-size: 1.6vmin;
        line-height:140% !important;
        text-align:left;
        flex-direction: column;
        justify-content: center;
        line-height: 2vmin;
        color: var(--elevvaTextTitle)
      
      }
    .letraPequeña{
        font-size: calc(0.4rem + 0.4vw) !important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    }
    .letraPequeñaTension_arterial{
        font-size: calc(0.4rem + 0.4vw) !important;
        color:var(--elevvaTextTitle);
        font-weight:500;
    }
}

.resplandor {
 
    
    animation-duration: 0.5s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;;
}
@keyframes changewidth {
    from {
        box-shadow: 0px 0px 10px #CB3D82;
    }
    to {
        box-shadow: 0px 0px 0px #CB3D82;
    }
}


.letraPequeñaotherzumba{
    font-size: 1.5vmin !important
}
.figurePartsInicio{
    width: 20vmin
}


.letraBodyInsidemoresmall{
    font-size: 1.1vmin !important;
    color:var(--elevvaTextTitle);
    font-weight:500;
}



.option-page{
    color:var(--elevvaTextTitle);
    cursor:pointer;
    align-items:center;
    width: 100%;
    padding:0% 8%;
   
}
.elevation-2 {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, .05), 0 3px 4px 0 rgba(0, 0, 0, .05), 0 1px 5px 0 rgba(0, 0, 0, .05)
}
.elevation-12 {
	box-shadow: 0 12px 16px 2px rgba(0, 0, 0, .05), 0 5px 22px 4px rgba(0, 0, 0, .05), 0 6px 13px 0 rgba(0, 0, 0, .05)
}
.elevation-18 {
	box-shadow: 0 18px 25px 3px rgba(0, 0, 0, .05), 0 7px 33px 6px rgba(0, 0, 0, .05), 0 9px 11px 0 rgba(0, 0, 0, .05)
}
.option-page.active{
    color:var(--elevva);
    background: var(--backgroundOnboardingContain)
}
.option-page.active .letraPequeña{
    color:var(--elevva) !important;

}
.option-page:hover{
    color:var(--elevva);

}
.option-page:hover .letraPequeña{
    color:var(--elevva) !important;

}

.bi-plus-circle-fill::before {
    font-weight:90 !important;
}
.howcenteritems{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.btnarrowPanel{
    width:3.5vmin !important;
    height:3.5vmin !important;
    border-radius:7px !important;
}
.btnarrowPanelColor{
    width:4vmin !important;
    height:4vmin !important;
    border-radius:7px !important;
    border:1px solid var(--backgroundOnboardingObjective) !important;
    background:var(--backgroundOnboardingObjective) !important;
}


.direcccionately_other{
    display:flex;
    flex-direction:column;
    justify-content: inherit;
}

.letrapequeñaProfilePlan{
    font-size: calc(1rem + 1.5vmin);
    color:var(--elevvaTexttitleLow);
    text-align:center
}
.letrapequeñaProfileDown{
    font-size:1.0vmin;
    color:var(--elevvaTextTitle);
    text-align:center;
}

.letrapequeñaProfileDown_otherPlan{
    font-size:calc(0.6rem + 0.1vmin);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    color:var(--elevvaTextTitle);
    text-align:center;
   
}
.swiper-button-next, .swiper-button-prev {
    color:var(--elevvaTextTitle) !important;
    width: 3vmin !important;
    height:3vmin !important;
    border-radius:50% !important;
    border:1px solid var(--white) !important;
    background:var(--white) !important
    
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-size:1.0vmin !important;
  font-weight:bold !important;
}
@keyframes anim {
    100%{
        stroke-dashoffset:var(--percentageCircularBar);
        /* stroke-dashoffset:150%; */
    }
}
.sliderNetFlixvideo video{
    border-radius:15px;
   
}
.resplandor-sliderNetFlixvideo video{
    animation-duration: 0.5s;
    animation-name: changewidth2;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@keyframes changewidth2 {
    from {
        box-shadow: 0px 0px 11px rgb(203,61,130,1);
       
    }
    to {
        box-shadow: 0px 0px 0px rgb(203,61,130,1);
       
    }
}
.marginal_zero{
    margin:0 !important;
    padding:0.3vmin !important;
    max-width:12vmin !important;
}
.otherButon_with_icon{
    max-width:65% !important;
    font-size:2vmin !important;
}
.position-image-quicly{

}

.btnmax {
    max-width: 265px !important;
}

.react-calendar__tile--active {
    background: #cb3d82 !important;
    border-radius: 18px !important;
}

.noMargin {
    margin: -1rem auto;
}

/* color de clase */
.color-reserva {
    background-color: #ffe9ca;
}

/* overflow*/ 

.profe {
    height: calc(100vh - 50vmin);
    overflow-y: auto;
}

/* Color SeleccionarProfe*/ 

.border-color {
    border-radius: 20px;
    /* border: 1px solid #000; */
}

.react-joyride__tooltip button[data-action="back"]{
    border:1px solid #CB3D82 !important
}
.react-joyride__tooltip button[data-action="primary"]:focus-visible {
    
    outline: none !important;
  }
.direcccionately{
    display:flex;
    flex-direction:column;
    justify-content: center;
   
}
.tooltip_bottom_select:after{
    z-index:-1;
        content: "";
        position: absolute;
        bottom: -6px;
        left: 50%;
        margin-left: -6px;
        width: 12px;
        height: 12px;
        background: rgb(243, 83, 155);
        transform: rotate(45deg);
      
}