:root{
  --elevva: rgb(203,61,130,1);
  --elevvabutonLogin: rgb(248,220,209,1);
  --elevvabutonLogin_User: rgb(123,134,250,1);
  --backgroundOnboarding: rgb(16,35,42,1);
  --backgroundOnboardingObjective: rgb(158,172,237,1);
  --backgroundOnboardingObjectiveBorderOff: rgb(224,231,255,1);
  --backgroundGraficGrid: rgb(244,244,246,1);
  --backgroundOnboardingContain: rgb(250,248,245,1);
  --elevvaTextLogin: rgb(141,102,95,1);
  --elevvaTextSubtitle: rgb(157,172,189,1);
  --elevvaTexttitleLow: rgb(173,186,196,1);
  --elevvaTextTitle: rgb(57,79,101);
  --elevvaTextTitle05: rgba(57,79,101,0.5);
  --elevvabackgroundImage-welcome: rgb(233,224,207,1);
  --elevvaTextLoginLbel: rgb(106,78,73,1);
  --elevvaTopNav: rgb(241,246,249,1);
  --elevvaArrowColor: rgb(168,194,215,1);
  --elevvaArrowColorCountry: rgb(206,214,222,1);
  --elevvaLineOnboarding: rgb(229,233,237,1);
  --elevvaBackgroundCreate: rgb(224,198,177,1);
  --elevvaBackgroundColorTrainer: rgb(230,194,172,1);
  --white: rgb(255,255,255);
  --colorNumber02: rgb(57,79,101,0.15);
  --colorsesiones: rgb(254,245,231,1);
  --colorsesionesHr: rgb(254,236,236,1);
  --colorsesionesTension: rgb(235,250,255,1);
  --colorsesionesTensionBack: rgb(18,105,176,1);
  --colorObjTraining: rgb(105,159,180,1);
  --colorsesionesKm: rgb(234,234,244,1);
  --colorsesionesCal: rgb(254,245,231,1);
  --objEntrenamiento: rgb(213,243,255,1);
  --white05: rgb(255,255,255,0.5);
  --colorNotifications:rgb(0,255,238,1);
  --textCal:rgb(255,161,15,1);
  --vercardtext:rgb(94,51,171,1);
  --barraPublicidad:rgb(128,143,243,1);
  --pointsGraficaCircle:rgb(225,98,80,1);
  --pointsGrafica:rgb(255,197,189,1);
  --pointsGraficaDistance:rgb(201,206,255,1);
  --pointsGraficaTension:rgb(152,225,249,1);
  --pointsGraficaHr:rgb(253,167,209,1);
  --downGrafica:rgb(254,247,246,1);
  --barProgress:rgb(245,215,207,1);
  --barProgressPersonId:rgb(247,235,232,1);
  --backgrounColorBar:rgb(249,249,249,1);
  --clasesPending:rgb(253,241,232,1);
  --verBooking:rgb(103,112,209);
  --level-actividad-sub:rgb(169,182,196,1);
  --programa-training:rgb(243,237,228,1);
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
     

     --percentageCircularBar:0%
}
#root {

  height:100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
