.labelLogin{
    color:var(--elevvaTextLoginLbel);
    font-size:0.9rem;
}
.labelLoginLaws{
    color:var(--elevvaTextLoginLbel);
    font-size:0.7rem;
}
.backgrounImgLogin{
    
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.backgrounImgLoginMobile{
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;  
}