.container-ProgressBar .progress-bar{
  font-size:0.8rem !important
}
.container_semana_activity{
  background:var(--backgroundOnboardingContain)
}
.container_semana_activity_mobile{
  background:#F9F9F9
}

.activeSelctJoy{
  z-index:999999;
  background:white
}
@media (min-width: 992px) and (max-width: 1350px) {
  #periodoSelect_dash{
    font-size: calc(0.4rem + 0.4vw)!important;
    /* min-width:calc(10rem + 1vmin); */
    border:1.7px solid #9DACBD !important;
    padding-right: 3rem !important;
    border-radius:20px !important;
  }
    .selector_items_time{
      min-width:calc(13rem + 1vmin);
      border:1.7px solid #9DACBD !important;
      border-radius:20px !important;
   
    
   }
}

@media (min-width: 1351px)  {
  #periodoSelect_dash{
    font-size: calc(0.4rem + 0.4vw)!important;
    /* min-width:calc(15rem + 1.5vmin); */
    border:1.7px solid #9DACBD !important;
    border-radius:20px !important;
    padding-right: 3rem !important;
   }
   .selector_items_time{
    min-width:calc(15rem + 1.5vmin);
    border:1.7px solid #9DACBD !important;
    border-radius:20px !important;
 
  }
}


@media (min-width: 992px)  {
 

  .selector_items_activity{
    min-width:calc(6rem + 2vmin);
  ;
  }

}
@media (max-width: 992px)  {
  
  .selector_items_time{
    min-width:calc(10rem + 2vmin);
    border:1px solid #9DACBD !important;
    border-radius:20px !important;
 
  }
#periodoSelect{
 font-size: calc(0.7rem + 0.4vw)!important;
}
#frecuenciaSelect{
  font-size: calc(0.7rem + 0.4vw)!important;
 }
  .selector_items_activity{
    min-width:calc(5.5rem + 2vmin);
    font-size:calc(0.7rem + 0.4vw)!important
  ;
  }
}