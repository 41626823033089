.text-objective-jus{
  line-height: 100%;
    display: flex;
    margin: auto 5%;
}

@media (max-width: 992px)  {
  .grid-container-perfil {
    display: grid;
    grid-template-columns: repeat(7, 47px); /* 7 columnas con ancho igual */
     /* Espacio entre las celdas */
     grid-column-gap: 2px; /* Espacio horizontal entre las celdas */
     
  
     
  }
  .letraNamePerfil{
    font-size:1.5rem;

    text-align:left;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    color: var(--elevvaTextTitle)
  }

  .imageCustomerPerfil{
    width: 7rem;
    height: 7rem;
    border: 1px solid var(--elevvaArrowColor);
    object-fit: cover;
  }
  .figurePartsPerfilNew{
  
    width: 36vmin;
   
}
}
@media (min-width: 992px)  {
  .grid-container-perfil {
    display: grid;
    grid-template-columns: repeat(7, 90px); /* 7 columnas con ancho igual */
     /* Espacio entre las celdas */
  
  
     
  }
  .figurePartsPerfilNew{
  
    width: 20.5vmin;
   
}
.letraPequeña-Perfil{
  font-size: calc(0.3rem + 0.4vw) !important;
  color: var(--elevvaTextSubtitle);
  font-weight: 400;
  padding:0 5%
}

.button-Body-perfil{
  font-size:calc(0.4rem + 0.4vw)!important ;
  max-width:calc(10rem + 1vmin) !important;
  padding:5px !important;

}

  .imageCustomerPerfil{
    width: 13vmin;
    height: 13vmin;
    border: 1px solid var(--elevvaArrowColor);
    object-fit: cover;
  }

  .letraNamePerfil hr {
  
    margin:0.2rem 0;
  }
  .letraNamePerfil{
    font-size: 1vmin;
 
    text-align:left;
    flex-direction: column;
    justify-content: center;
   
    color: var(--elevvaTextTitle)
  
  }
}

.containerCompras {
  display: flex;
  flex-wrap: wrap;
}

.itemCompras {
  flex-basis: 33.33%; /* Ajusta el ancho de cada elemento para ocupar 1/3 del contenedor */
  box-sizing: border-box;
  padding: 1%;

}
.containerCompras hr {
  width:100%;
}

.dotted-line-container {
  position: relative;
}

.dotted-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px dashed #C4968F;

}

.dotted-line-vertical {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1px;
  border-left: 1px dashed #C4968F;
}

.dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #C4968F;
}





.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.box-content {
  font-size: calc(0.6rem + 0.5vmin);
  color:#394F65;
}

.box-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:6% 2%;
  height: 30px;
  width: 30px;
  margin: 5px;
}

.box-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:5%;
  height: calc(2.0rem + 1vmin);
  width: calc(2.0rem + 1vmin);
  margin: 5px;
}

