.imagen-createUser-background{
   
    background-repeat:no-repeat;
     background-size:cover;
     
     background-position: center;
 
 }
 .text-color-subtitle-membership{
   font-size:1rem;
   color:#9DACBD !important
}
 .errorsCreateAccount{
   color: #CB3D82;
   font-size:calc(5px + 1vmin);
 }
.imagen-createTrainer-background{
  
    background-repeat:no-repeat;
     background-size:cover;
     
     background-position: center;
 
 }
 .containerBackgroundCreateuser{
    position: relative;
    height: 35vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
 }
 .PhoneInputCountrySelectArrow{
font-size:2rem !important;
color:var(--elevvaArrowColorCountry) !important;
border-bottom-width: 1px solid var(--elevvaArrowColorCountry) !important;
border-right-width: 1px solid var(--elevvaArrowColorCountry) !important;
 }
 .react-tel-input .selected-flag .arrow{
    border-top: 5px solid var(--elevvaArrowColorCountry) !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;

 }
 .react-tel-input .flag-dropdown {
    width: 18%;
    margin-right: 2%;
 }
 .react-tel-input .form-control{
    /* width: 82%;
    margin-left: 18%; */
    width: 80%;
   
    margin-left: 20%;
    font-size:1rem;
    

 }

 .PhoneInput input, .PhoneInputCountry{
    border-radius: 20px !important;
   
    padding:10px 20px !important;
    border:1px solid var(--elevvaTextSubtitle) !important;
 }
 .react-tel-input input, .flag-dropdown  {
    border-radius: 20px !important;
   
    padding:0px 20px !important;
    border:1px solid var(--elevvaTextSubtitle) !important;
 }
 .react-tel-input {
   font-family: inherit;
   font-size: inherit;}

 .phone-web .react-tel-input input, .phone-web .flag-dropdown{
    border-radius: 20px !important;
   background-color:var(--white);
    padding:22px 4% !important;
    border:1px solid var(--elevvaTextSubtitle) !important;
 }
 .react-tel-input .selected-flag{
    margin:auto !important;
    padding: 0 0 0 4px;
 }
 .phone-web .PhoneInput input, .phone-web .PhoneInputCountry{
    border-radius: 20px !important;
   
    padding:10px 40px !important;
    border:1px solid var(--elevvaTextSubtitle) !important;
 }
 #TyC a{
color: var(--elevva);
 }
 .box-create-account{
    border:1px solid var(--white);
   position: relative;
    background:var(--white);
justify-content: center;


height:100vh;

border-top-left-radius:30px;
border-bottom-left-radius:30px;  
}
 .box-create-account-inside{

   position: absolute;
   
 display:inline-flex;
    background:var(--white);
    width:100%;
   
    padding:10% 4%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);



}
.container-create-web-customer{
    background:var(--elevvaBackgroundCreate);
    
}
.container-create-web-trainer{
    background:var(--elevvaBackgroundColorTrainer);
    
}
.eye-color-location{
 
        position: absolute;
        top: 50%;
        color:var(--elevvaTextSubtitle) ;
        left: 85%;
        z-index: 1;
        transform: translate(-50%, -50%);
        font-size:1.3rem;
      
}
.select-register-location{
 
   position: absolute;
   top: 50%;
   color:var(--elevvaTextSubtitle) ;
   left: 92%;
   z-index: 1;
   transform: translate(-50%, -50%);
   font-size:1.3rem;
 
}