.figure_your_body{
  width:28vmin;
}
.cuadrorangos {
  pointer-events:none;
}
.cuadrorangoschange {
  pointer-events:none;
}
.imageInfo_icon{
  width:1.5rem;
  display:flex;
  justify-content: center;
  
}
.letra_body_info{
  font-size:calc(0.6rem + 0.4vmin) !important;
}
.letra_body_info.active{
  color:var( --elevvabutonLogin_User)
}
.cuadrorangos .rc-slider-mark-text {
  font-size: calc(0.5rem + 0.4vmin);
  margin-left: -13%!important;
  max-width:5rem;
}
.cuadrorangoschange .rc-slider-mark-text {
  font-size: calc(0.5rem + 0.4vmin);
  margin-left: -25%!important;
 
}

.rc-slider-track {
  background-color: var(--pointsGrafica) !important;
}
.rc-slider-dot {
  border: 2px solid #fff!important;
}
.rc-slider-handle {
  border: 3px solid var(--pointsGraficaCircle) !important;
}
.rc-slider-step {
  background: var(--pointsGrafica) !important;
}
.kilogramos{
  width:80%;
  margin:0 auto;
  padding-top:1%
}
.kilogramos div{
color:var(--elevvaArrowColor);
font-weight:600;
  font-size: calc(0.6rem + 0.4vmin);
 
}
