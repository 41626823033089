
.playervideoIndividual img {
    border-radius:25px  !important;
    object-fit: initial !important;
    border:1px solid rgba(0,0,0,0.40);
    box-shadow:0px 0px 10px -2px rgba(0,0,0,0.50);
    
    }
    .playervideoIndividual video {
        border-radius:25px  !important;
        /* object-fit: fill !important; */
   
        /* border:1px solid rgba(0,0,0,0.40);
        box-shadow:0px 0px 10px -2px rgba(0,0,0,0.50); */
        
        }
.playervideoIndividual {
padding:2%;

}
.playervideoIndividual .react-player__preview{
    border-radius:20px
}

 .playervideo__Optional .react-player__preview{
    border-radius:20px;
    height:140px !important;
 
} 

@media (min-width: 100px) and (max-width: 920px) {
    .buttonIndividualTypes{
        border:2px solid #CB3D82;
        background:transparent;
        color: #CB3D82;
       border-radius:20px;
       font-size:0.7rem;
       padding:2% 3.5%;
       max-width:55%;
       margin:0 2% 0 0;
       
    }
    .buttonIndividualTypesverahora{
        border:2px solid #CB3D82;
        background:#CB3D82;
       border-radius:20px;
       font-weight:bold;
       color:#ffff;
      
       padding:2% 5%;
       margin:0;
       
    }
}
@media (min-width: 921px) {
    .buttonIndividualTypes{
        border:2px solid #CB3D82;
        background:transparent;
       border-radius:20px;
       font-size:1.3vmin;
       padding:1vmin 3%;
       margin:0 2% 0 0;
       color:#CB3D82;
       
    }
    .buttonIndividualTypesverahora{
        border:2px solid #CB3D82;
        background:#CB3D82;
       border-radius:20px;
       font-weight:bold;
       color:#ffff;
       font-size:1.3vmin;
       padding:1vmin 3%;
       margin:0;
       
    }  
}