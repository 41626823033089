.background-left-onboarding{
background-color:var(--backgroundOnboarding);
height:100vh;  
position: relative;
}
.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    background:var(--elevva) !important; z-index: 0 !important
 
}
.pointerinAvatar{
    cursor:pointer
}

.slider_form_5{
    max-height:650px
}
.words_health{
    color:var(--elevvaTextTitle);

}
.rdrMonthAndYearWrapper{
    background:var(--backgroundOnboardingObjective)
}
.rdrMonthAndYearPickers select{
    background-color:var(--backgroundOnboardingObjective) !important;
    
    color:var(--white) !important
}
/* .swiper-container {
overflow:auto !important
} */
.rdrDayHovered span{
    color:#ffff !important
}

#swiper_onboarding_customer .swiper-slide {
    visibility: hidden;
    }
    
    #swiper_onboarding_customer .swiper-slide.swiper-slide-active {
    visibility: visible;
    }

@media (max-width: 992px)  {
    .scrollObjectives{
        height:45vh;
        width:100%;
    }
    .otherheigthObjectives{
        height:70%; 
    }
    .sizebuttonLarge{
        max-width:100%;
        width:50vw;
    }
    .FaltanItems{
        color: #9DACBD;
        margin:1rem 1rem 1rem 1rem;
    }
    .text_icons_responsive{
       font-size: calc(0.5rem + 0.5vmin)
    }
    .objetivo_text_ve{
        width: 18vmin !important;
        height: 18vmin  !important;
        min-height: 3vmin;
        
        background-color:var(--backgroundOnboardingObjectiveBorderOff);
        min-width: 3vmin;
        border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 50% 50%;
        -moz-background-size: 50%;
        background-size: 50%;
    }
  

    .formSteps .objetivoP #icon {
        width: 18vmin !important;
        height: 18vmin  !important;
        min-height: 3vmin;
        
        background-color:var(--backgroundOnboardingObjectiveBorderOff);
        min-width: 3vmin;
        border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 50% 50%;
        -moz-background-size: 50%;
        background-size: 50%;
    }

    .marginButtonLesion{
margin: 0 auto;
    }

    .figureParts{
  
        width: 36vmin;
       
    }

    .longResponsive{
width:100%;
    }

    .tooltipPhoto { 
        width:50%;
        border-radius: 20px !important;
        opacity: 1 !important; 
    }

    .btnInfo {
        z-index:2 ;
        width: 7vmin;
        right:10%;
    }

    .slider_form{ overflow-y: auto !important;
    
      
      
    }
    .swipersep{
       display: inline-block;
        width: 20px;
        border-top: 2px solid #F2F2F2!important;
    height:2px;
    background-color: transparent;
    padding: 0;
    margin:  auto 0;
       }
       .swipersep.noactive {
        display: flex;
        border-radius:1px !important;
        border-top: 2px solid #F2F2F2!important;
        color: none!important;
    }

       .swipersep.active {
    
        display: flex;
        border-radius:1px !important;
        border-top: 2px solid  #7B86FA!important;
        background-color: #7B86FA !important;
     
        color: none!important;
    }

    .stepsBullets .Bullet {
        display: inline-block;
        width: 30px;
        margin:auto 0;
        height: 28px;
        
        border-radius: 15px;
        border: 3px solid #F2F2F2;
        color:#9DACBD;
        background-color: transparent;
        opacity: 1;
        cursor: pointer;
        z-index:2
        }

 .bulletsOnbor{
    bottom:0 !important;
    position:relative;
    height: 5%;
}
 .bulletsOnborMembership{
    bottom:0 !important;
    position:relative;
    height: 5%;
}

    .background-right-onboarding{
        background-color:var(--white);
        height:100vh;  
        justify-content: center;
        align-items:center;
        position: relative;
        }
        .CompletarPerfil {
            background-color: white;
          
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background-color: #fff;
            overflow-x: hidden;
            overflow-y: hidden;
        }
        .rdrMonth {
            width: 20.667em !important;
        }
        .CompletarFooter {
            position: fixed !important ;
            background-color: transparent;
        
        z-index:2;
        top:100% !important;
        left: 50%;
        transform: translate(-50%, -95%);
           justify-content: center;
          
            align-items:center;
           display:inline-flex;
             width: 100%;
             color: white;
        
             padding: 0;
             margin:auto;
             border: 00 !important;
             cursor: pointer;
           
         } 
         .contain-info-onboarding{
   overflow-y: auto;
   height: 100%;
            width:100%;
            
            z-index: 2;
            position:absolute;
           
            border-radius:20px;
            justify-content: center;
        }

        .contain-info-onboarding-Objectives-member{
            overflow-y: auto;
            height: 100%;
                     width:100%;
                     
                     z-index: 2;
                     position:absolute;
                    
                     border-radius:20px;
                     justify-content: center;
                 }

        .stepsBullets{
            display:flex;
          
            justify-content: center;
            
        }
        .leftTextoInterno{
            text-align:center
        }

        .labelPhoto {
            box-sizing: border-box;
            width: 33vmin;
           
            height: 33vmin;
           background-color:#9EACED;
            border: 6px solid #9EACED;
            background-repeat: no-repeat;
            background-size: cover;
            background-position:center;
            z-index: -1;
            position: relative;
           
        }

        .objetivo_text_ve #text{
            text-align: center;
            font-size: calc(.6rem + 0.5vw) !important;
        letter-spacing: 0px;
        color: rgb(158,172,237,1);;
        }

        .objetivo_text_ve.active #text{
            text-align: center !important; 
           font-weight:bold;
           font-size: calc(.6rem + 0.5vw) !important;
        letter-spacing: 0px;
        color:var(--white) !important;
        }
        

}

  
@media (min-width: 993px)  {
    /* .containerCuerpo {
        width: 21vmin;
       
        position: relative;
        top: 0%;
        left: 0%;
    } */
    .FaltanItems{
        color: #9DACBD;
        margin:1rem 2rem 2rem 2rem;
    }
    .justify-objetivoP{
        justify-items:center !important;
        width:calc(5rem + 0.4vmin) !important;
    }

    .text_icons_responsive{
        font-size: calc(0.7rem + 0.3vmin)
     }

    .objetivo_text_ve.active #text{
        text-align: center !important; 
       font-weight:bold;
       line-height:1;
       font-size: calc(.45rem + 0.8vmin) !important;
    letter-spacing: 0px;
    color:var(--white) !important;
    }
    

    .objetivo_text_ve #text{
        text-align: center;
        line-height:1;
        font-size: calc(.45rem + 0.8vmin) !important;
    letter-spacing: 0px;
    color: rgb(158,172,237,1);;
    }

    .borderHave{
        border-bottom:  1px solid #E5E9ED
      }

    .formSteps .objetivoP #icon, .objetivo_text_ve {
        width: calc(5rem + 0.4vmin) !important;
        height: calc(5rem + 0.4vmin)  !important;
        min-height: 3vmin;
        
        background-color:var(--backgroundOnboardingObjectiveBorderOff);
        min-width: 3vmin;
        border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 50% 50%;
        -moz-background-size: 50%;
        background-size: 50%;
    }

    .figureParts{
  
        width: 20.5vmin;
       
    }

    .longResponsive{
        width:50%;
            }

    .tooltipPhoto { 
        width:30%;
        border-radius: 20px !important;
        opacity: 1 !important; 
    }

    .labelPhoto {
        box-sizing: border-box;
        width: 30vmin;
       
        height: 30vmin;
       background-color:#9EACED;
        border: 6px solid #9EACED;
        background-repeat: no-repeat;
        background-size: cover;
        background-position:center;
        z-index: -1;
        position: relative;
       
    }

    .btnInfo {
        z-index:2 ;
        width: 5vmin;
        right:10%;
    }

    .leftTextoInterno{
        text-align:left
    }

    .swipersep{
        position:relative;
          text-align:center;
           border-left: 2px solid var(--elevvaLineOnboarding);
           height:30px;
           left: 50%;
       }

    .stepsBullets .Bullet {
        text-align:center;
        margin:auto;
        padding:1%;
        align-items:center;
        width:5vmin;
        height:5vmin;
            border-radius: 30px;
            border: 2px solid var(--elevvaLineOnboarding);
            color: var(--elevvaTextSubtitle);
            background-color: transparent;
            opacity: 1;
            cursor: pointer;
        }
    .bulletsOnbor{
        z-index: 2;
        position:absolute;
        left:26% !important;
        bottom:25% !important;
        width: 8.33333333% !important;
    }
    .bulletsOnborMembership{
        z-index: 2;
        position:absolute;
        left:26% !important;
        bottom:50% !important;
        width: 8.33333333% !important;
    }

    .stepsBullets{
        display:flex;
        flex-direction:column;
        justify-content: center;
        
    }

    
    .contain-info-onboarding{
   
        width:80%;
        height:80%;
        z-index: 2;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius:20px;
        justify-content: center;
    }

    
    .contain-info-onboarding-Objectives-member{
   
        width:80%;
        height:100%;
        z-index: 2;
        position:absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius:20px;
        justify-content: center;
    }

    .CompletarFooter {
        position: fixed !important ;
        background-color: transparent;
    
    z-index:2;
    top:95% !important;
    left: 83%;
    transform: translate(-50%, -83%);
       justify-content: center;
      
        align-items:center;
       display:inline-flex;
         width: 50vmin;
         color: white;
    
         padding: 0;
         margin:auto;
         border: 00 !important;
         cursor: pointer;
       
     } 

    .background-right-onboarding{
        background-color:var(--backgroundOnboardingContain);
        height:100vh;  
        justify-content: center;
        align-items:center;
        position: relative;
        }
}



.logo-center-onboarding{
    margin:auto;
    padding: 8%;
    width:90%;
}
.inside-content-number{
font-size:40vmin;
position: fixed;
font-weight:700;
bottom: -22%;
    left: 50%;
color:var(--colorNumber02);
transform: translateX(-50%);
z-index: 0;
}
.img-logo-back-onboarding{
    width:40vmin;
   
}
.inside-content-imgLogo{
   
    position: fixed;
    bottom: 5%;
    right:10%;
        
  
    z-index: 0;
    }
.inside-content-full{
    background-color:var(--white05);
    width:90%;
    height:90%;
    z-index: 1;
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius:20px;
    justify-content: center;
}
.centraNumberSteps{
    position:absolute;
   
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.photoProfile {
   width:100%;
   height:100%;
    opacity: 0;
 top:0;
 left:0;
    overflow: hidden;
    position: absolute;
    z-index: 3;
    cursor:pointer;
}
.linebodyInicio hr{
    color: var(--elevvaTexttitleLow);
    font-weight:bold;
    border-top:2px solid;
    opacity:1;
}


.stepsBullets .active {
border-left: 2px solid #7B86FA;
}





.stepsBullets .Bullet.active {
    border: 2px solid var(--elevvabutonLogin_User);
    color: white !important;
    background-color: var(--elevvabutonLogin_User);
}
.CompletarFooter .siguiente {
    height: 100%;
    border-radius:0px !important;
    color: #9DACBD;
  
    /* background-color: #D4E8FF; */
    
    display: flex;
    align-items: center;
    justify-content: center;
}

 .CompletarFooterOnboarding-one {
    position: absolute !important ;
    background-color: transparent;

z-index:2;
top:100%;
left: 50%;
transform: translate(-50%, -100%);
   justify-content: center;
  
    align-items:center;
   
    /* width: 45vmin; */
     color: white;

     padding: 0;
     margin:auto;
     border: 00 !important;
     cursor: pointer;
   
 } 
 .CompletarFooter div {
    /* border-radius:55px;
    height: 100%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center; */
}
.CompletarFooter .anterior {
    height: 100%;
    border-radius:0px !important;
    width: 40%;
    border-top-left-radius:50px !important;
    border-bottom-left-radius:50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-camera-responsive{
    width: 100%;
    max-width:10vmin;
}
.bulletsPublicidad {
    display: flex;
    justify-content: center;

}
.bulletsPublicidad .swiper-pagination-bullet {
    width: 3vmin;
    height: 3vmin;
border-radius:20px;
    opacity:0.3;
    background-color: #9EACED;
}
.bulletsPublicidad .swiper-pagination-bullet.active {
    background-color: #9EACED;
    opacity: 1;
}
.iconavatar{
   padding:5%;
    width:12vmin;
    height:12vmin;
    text-align:center;

}
.buttonIcon_Avatar{
    background-position:center;
    text-align:center;
    background:transparent;
    border:1px solid var(--white);
}
.buttonIcon_Avatar:hover{
    border:1px solid var(--elevva);
    border-radius:20px;
}

.formS-filter {
    width: 8vmin !important;
    height: 8vmin !important;
    
}
.formSteps .objetivo, .objetivo_text {
    padding-bottom: 1%;
    display: grid;
    justify-items: center;
}
.formSteps .objetivoP #icon, .objetivo_text {
    width: 10vmin;
    height: 10vmin;
    min-height: 3vmin;
    
    background-color:var(--backgroundOnboardingObjectiveBorderOff);
    min-width: 3vmin;
    border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
    background-position: center;
    background-repeat: no-repeat;
    -webkit-background-size: 50% 50%;
    -moz-background-size: 50%;
    background-size: 50%;
}


.formSteps .objetivoP, .objetivo_text {
    padding-bottom: 1%;
    display: grid;
    justify-items: center;
}
.formSteps .objetivoP, .objetivo_text_ve {
    padding-bottom: 1%;
    display: grid;
    justify-items: center;
}



.dolor {
    width: 20px;
    height: 20px;
    background:transparent;
    box-shadow: 2px 2px 4px #000000;
 position: absolute;
}
.objetivo_text.objetivo_text_small #text{

    width: 10vmin !important;
padding-bottom:0% !important;
    height: 10vmin !important;
border: 5px solid rgb(158, 172, 237,0.5);
}
.objetivo_text.objetivo_text_ve #text{

    width: 10vmin !important;
    color:var(--backgroundOnboardingObjective) !important;
    font-size: calc(.4rem + 0.5vw) !important;
padding-bottom:0% !important;
    height: 10vmin !important;
border: 5px solid rgb(158, 172, 237,0.5);
}
.objetivo_text #icon {
    width: 10vmin !important;
height: 10vmin !important;
background-size:6vmin !important;
border: 5px solid rgb(158,172,237,0.5);
background-position: center;
background-repeat: no-repeat;
}
 .objetivo_text #text{
    text-align: center;
   
letter-spacing: 0px;
color: rgb(158,172,237,1);;
}

.objetivo_text_ve{
 
    position:relative !important;
}
.objetivo_text_ve.active{
    background:var(--backgroundOnboardingObjective) !important;
    color:var(--white);
    position:relative !important;
}



.texto_footer {
    position: relative;
    margin-bottom: -128px;
    left: 49%;
}

.next_stop {
    top:80%;

    right:6%;
    right:6%;
}

@media (max-width: 766px)  {

    
.escoger_avatar_bottom{
    margin:0 auto 
}
    .formSteps .objetivo #icon {
        width: 13vmin;
        height: 13vmin;
        min-height: 3vmin;
        
        background-color:var(--backgroundOnboardingObjectiveBorderOff);
        min-width: 3vmin;
        border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 50% 50%;
        -moz-background-size: 50%;
        background-size: 50%;
    }

}
@media (min-width: 767px) and (max-width: 1500px) {
  
 
    .formSteps .objetivo #icon {
        width: 12vmin;
        height: 12vmin;
        min-height: 3vmin;
        
        background-color:var(--backgroundOnboardingObjectiveBorderOff);
        min-width: 3vmin;
        border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 50% 50%;
        -moz-background-size: 50%;
        background-size: 50%;
    }
}
@media (min-width: 1501px) {
    .formSteps .objetivo #icon {
        width: 13vmin;
        height: 13vmin;
        min-height: 3vmin;
        
        background-color:var(--backgroundOnboardingObjectiveBorderOff);
        min-width: 3vmin;
        border: 6px solid var(--backgroundOnboardingObjectiveBorderOff);
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: 50% 50%;
        -moz-background-size: 50%;
        background-size: 50%;
    }
}