input[type="radio" i] {
    background-color: #CB3D82 !important;
    cursor: pointer;
    margin: 3px 3px 0px 5px;
  width:20px;
  height:20px;
  
}
.changecolortable:nth-child(n) {
    background-color:#F4F0ED;
  
}
.changecolortable:nth-child(2n+1) {
    background-color: #EDEEF5;
  
}
.changecolortable:nth-child(4n+1) {
    background-color: #EEEEEE;
}



.content-inputCompanies input[type=radio]:checked+i:before{
    transform: scale(1);
    opacity: 1;
}


.marginButtonReserva{
    margin:0 !important
}
.redondo-butoon{
        border: 1px solid var(--elevvaTextSubtitle);
        background: transparent;
        border-radius:10px;
}
/* .tonin-prev:after,.tonin-next:after {
    font-size:15px !important;
    color:white;
        }
       .tonin-next{
        position:relative !important;
        background:#9EACED !important;
        margin:auto ;
        width:4vmin !important;
        height:4vmin !important;
        top: 0 !important;
        border-radius:50% !important;
        border:1px solid #9EACED;
        z-index:100;
        }
        .tonin-prev{
            position:relative !important;
            background:#9EACED !important;
            margin:auto ;
            width:4vmin !important;
            height:4vmin !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
            z-index:100;
        }
        .tonin-prev:after {
            content: 'prev';
            font-family: swiper-icons;
            font-size: 15px !important;
            font-variant: initial;
            height: 100%;
            justify-content: center;
            text-transform: none!important;
            letter-spacing: 0;
            display: flex;
            cursor: pointer;
            align-items: center;
        }
        .tonin-next:after {
            content: 'next';
            font-family: swiper-icons;
            font-size: 15px !important;
            font-variant: initial;
            height: 100%;
            cursor: pointer;
            justify-content: center;
            text-transform: none!important;
            letter-spacing: 0;
            display: flex;
            align-items: center;
        } */

        .growing{ position:fixed !important;
           
            min-width:28vmin !important;
            bottom:1.5vmin;
       z-index:9999999999999999999999999999999 !important;
         transition-duration: 0.8s;
    
          filter: drop-shadow(0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5)); 
         
        }
        .moro{
            margin-top:3vmin
        }
        .input-style-elevva-pay {
            border-radius: 20px !important;
            padding: 1vmin 10px !important;
            /* border: 1px solid var(--elevvaTextSubtitle) !important; */
            border: 1px solid var(--elevvaTextSubtitle) !important;

        }

.rosa-background input[type="radio"]:checked {
    border: 2px solid #CB3D82 !important;
  }
  input[type="radio"]:checked {
    background-color: #CB3D82;
  }