@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
body, h1, h2, h3, h4, h5, h6,label,div,u,span,input,button,textarea  {
  margin: 0;
  font-family: 'Work Sans', sans-serif !important ;
  font-size:16px;
  
 
}
body {
  font-family: 'Work Sans' , sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.0;
  height: 100%;
  /* mobile viewport bug fix */

 
 
}



code {
  font-family: 'Work Sans', sans-serif
}