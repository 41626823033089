.bi-chevron-right::before,.bi-chevron-left::before {
    font-weight:bolder !important;
    font-size: 1.3rem !important;
    vertical-align:middle !important;
    display:flex;
}
/* .Registro .bi::before {
    vertical-align:middle !important
} */
.rw-widget-input {
    border-radius:20px !important;
    min-width:45vmin !important
}

.rw-multiselect-tag:first-child {
    border-top-left-radius:15px !important; 
    border-bottom-left-radius:15px !important; 
}
/* .text-left{
    text-align:left;
} */
#buttonSendMessage{
    border:none;
}
.searchprueba-now .container {
    position: absolute;
    margin: auto 0% auto auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 300px;
    z-index: 999999999999999;
    height: 100px;  
}
.searchprueba-now .container .search {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background: #7B86FA;
    border-radius: 50%;
    transition: all 1s;
    z-index: 4;
    
}
.searchprueba-now .container input {
 
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    outline: none;
    border: none;
    background: #7B86FA;
    color: white;

    padding: 0 80px 0 20px;
    border-radius: 30px;


    transition: all 1s;
    opacity: 0;
    z-index: 5;
    
    letter-spacing: 0.1em;
}
.searchprueba-now .container input:hover {
    cursor: pointer;
  }
  
  .searchprueba-now .container input:focus ~ .search {
    right: -200px;
    background: #CB3D82;
    z-index: 6;
}

    .searchprueba-now .container input:focus ~ .search::before {
        top: 0;
        left: 0;
        width: 0; 
     height: 0; 
    
      }
      .searchprueba-now .container input:focus ~ .search::after {
        top: 0px;
        width: 0; 
        height: 0; 
        left: 8px;
        border-left: 25px solid #ffff;
        border-top: 12px solid #CB3D82;
        border-bottom: 12px solid #CB3D82; 
        transform: rotate(0deg);
       
      }
      .searchprueba-now .container input::placeholder{
        color: white !important;
       
    
      }
  .searchprueba-now .container input:focus {
    width: 300px;
    left:-80px;
    opacity: 1;
    cursor: text;
  }
.searchprueba-now .container .search:hover {
    cursor: pointer;
  }
  .searchprueba-now .container .search::before {
    content: "";
    position: absolute;
    margin: auto;
    top: -3px;
    right: 0;
    bottom: 0;
    left: 0px;
    width: 25px;
    height: 25px;
    background: white;
    border-radius:20px;
  
    
  }
  .searchprueba-now .container .search::after {
    
    content: "";
    position: absolute;
    margin: auto;
    top: 18px;
    right: 0px;
    bottom: 0;
    left: 4px;
    border-left: 10px solid white;
    border-top: 10px solid transparent;
    width: 6px;
    height: 10px;
    transform: rotate(35deg);
   
  
   
  }

.imagenload{
    background-image:url("../assets/img/logosconvierte/MOB-LOADING.gif");
    background-size: cover;
    background-repeat: no-repeat;
}
.btnMOB {
    font-weight: 400;
    font-size: calc(12px + 1vmin);
  
    border-radius: 32px;
    padding: 10px;
    border: hidden;
    width: 60vw;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:1rem auto;
}
.box-Welcome-login {
    overflow-y:auto;
    border:1px solid var(--white);
    align-items:center;
    padding:0 15%;
    background:var(--white);
    justify-content: center;
    height:100%;
    border-top-left-radius:30px;
    border-bottom-left-radius:30px;
}
.box-Welcome-create {
    overflow-y:auto;
    border:1px solid var(--white);
    align-items:center;
    padding:0 10%;
    background:var(--white);
    justify-content: center;
    height:100%;
    border-top-left-radius:30px;
    border-bottom-left-radius:30px;
}
.container-login-welcome-web{
    background:var(--elevvabackgroundImage-welcome);
    
}


.mobile-welcome-login-row{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px ;
    right: 0;
    position: absolute;
    top:  33vh;
    bottom: 0;
    left: 0;
    height:  auto;
    display: flex;
    justify-content: center;
    background: var(--white);
    flex-direction: column;
   
 
  
}
.mobile-welcome-perfil {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px ;
    right: 0;
    position: absolute;
    top:  33vh;
    bottom: 0;
    left: 0;
    height:  auto;
    display: flex;
    justify-content: center;
    background: var(--white);
    border-color: var(--white);
    flex-direction: column;
   
 
 
}
.containerBGClase {
    position: relative;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  #text-w-line {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid var(--elevvaTextSubtitle); 
    line-height: 0;
    margin: 0.5rem 0; 
      
  }

  #text-w-line span { 
    background:#fff; 
    padding:0 0.5rem; 
}

.imagen-LoginCustomer-Welcome{
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}
.btnArrow{
    border-radius: 25%;
    width: 3rem;
    max-width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    display: flex;
}
.paddingGrowTeacher {
    padding:2%
}

/* #sliderConoceme .swiper-wrapper{
    justify-content: center;
    
} */

@media (min-width: 1500px) {
    .paddingGrowTeacher {
        padding:5% 2%
    }
}


@media (min-width: 1100px) {
    .modalInfo .modalInfoContenido {
        max-width: calc(100vw - 70%);
        background-color: white;
        padding: 3%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }
    .modalInfo .modalInfoContenido_plan {
        max-width: calc(100vw - 70%);
        background-color: white;
        padding: 3%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }
    .errorRequest{
        font-size:calc(0.5rem + 0.3vw)
    }
      .text-color-elevva, .buttonLoginStart,  .text-color-subtitle,.text-color-subtitle span, .form-control {
          font-size:calc(0.5rem + 0.5vw) !important;   
      }
  
    .total-width-login {
      width:88%;
    }
    .text-color-subtitle{
        font-size:1rem;
    }
  
    .total-width-arrow {
      width:14%;
    }

    .spacebuttonLogin{
        white-space: nowrap;
          font-size:calc(0.5rem + 0.5vw) !important; 
          max-width:45vmin !important;    
      }
  
  }
  
  @media (min-width: 768px) {

    .name_TrainingCard{
        font-size: 2vmin;
                    line-height: 100%
    }

    .contenedorInfo_trainer{
        justify-content: space-around;
        flex-direction: column;
        text-align: center;
        gap:2%;
        padding: 2%;height:100%
    }
  }
  @media (max-width: 767px) {
    .name_TrainingCard{
        font-size: 0.9rem;
                    line-height: 100%
    }

    .contenedorInfo_trainer{
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap:2%;
        padding: 2%;height:90%
    }

    .modalInfo .modalInfoContenido {
        width: calc(100vw - 20%);
        background-color: white;
        padding: 6% 4%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }
    .modalInfo .modalInfoContenido_plan {
        width: calc(100vw - 10%);
        background-color: white;
        padding: 6% 4%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }
    
  .total-width-login {
    width:100%;
    padding:5% 7%;
    height: auto;
    overflow:auto
  }

    .spacebuttonLogin{
        white-space: nowrap;
          font-size:calc(0.8rem + 0.5vw) !important; 
          max-width:300px;    
      }
    .errorRequest{
        font-size:calc(0.8rem + 0.3vw)
    }
      .text-color-elevva, .buttonLoginStart,  .text-color-subtitle,.text-color-subtitle span, .form-control {
          font-size:calc(1rem) !important;   
      }
}

  @media (min-width: 767px) and (max-width: 1100px) {

    .modalInfo .modalInfoContenido {
        width: calc(100vw - 60%);
        background-color: white;
        padding: 3%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }
    .modalInfo .modalInfoContenido_plan {
        width: calc(100vw - 60%);
        background-color: white;
        padding: 3%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }

    .spacebuttonLogin{
        white-space: nowrap;
          font-size:calc(0.5rem + 0.5vw) !important; 
          max-width:45vmin !important;    
      }

    .errorRequest{
        font-size:calc(0.5rem + 0.3vw)
    }
      .text-color-elevva, .buttonLoginStart,  .text-color-subtitle,.text-color-subtitle span, .form-control {
          font-size:calc(0.5rem + 0.5vw) !important;   
      }

    .btnArrow{
        border-radius: 25%;
        width: 2.5rem;
        max-width: 2.5rem;
        height: 2.5rem;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        display: flex;
    }
  
    .total-width-login {
      width:84%;
    }
  
    .total-width-arrow {
      width:16%;
    }
    
  }

.button-back-start{
    float:right;
    margin:1rem;
}
.ubication_login{
    position: absolute;
    bottom: 0;
 
    margin: 0 25%;
    display: flex;
}
.ubication_loginWeb{
   display:flex;
   flex-direction:column;
   justify-content: center;
   height:100%;
   margin-left:50%
}
.logoElevva_size{
    width:28vmin;
    max-width:450px;
}
.text-color-elevva{
    font-weight:500;
    color:var(--elevva)
}
.text-color-title{
    font-weight:400;
    color:var(--elevvaTextTitle); 
     
}
.text-color-title-low{
    font-weight:300;
    color:var(--elevvaTexttitleLow);
    white-space: nowrap; 
}
.content-select-gender select {
  background: URL("../assets/img/selector_member_arrow.svg") no-repeat 90%;
  background-position-y:center; 
  background-size:1rem; 
    }
    .content-select-member select {
        background: URL("../assets/img/selector_member_arrow.svg") no-repeat 90%;
        background-position-y:center; 
        background-size:1rem; 
          }

    .content-select-gender_activity select {
        background: URL("../assets/img/selector_member_arrow.svg") no-repeat 90%;
        background-position-y:center; 
        background-size:calc(0.5rem + 0.5vw); 
          }
.text-color-subtitle{
    font-weight:500;
     
    color:var(--elevvaTextSubtitle);
    
}
.text-color-error{
    font-weight:500;
    color:var(--elevva);
    
}

.input-style-elevva::placeholder{
    color:var(--elevvaTextSubtitle) !important;
}
.input-style-elevva {
   
    border-radius: 20px !important;
    padding:10px !important;
    border:1px solid var(--elevvaTextSubtitle) !important;
}

.mobile-login{
    background:var(--elevvabackgroundImage-welcome);
  
    display:flex;
    flex-direction:column;
  
}
.arrow-white-on-button{
display:flex;
justify-content: center;
align-items: center;
flex-direction: column;
  font-weight:bold;

    
}
.MuiInputBase-input::placeholder{
color:var(--elevvaTextSubtitle) !important;
opacity:1 !important;

font-family:Work Sans;
}


  #birthday-date::-webkit-calendar-picker-indicator {
    color: transparent;
    background:  none  ;
    z-index: 1;
    position:absolute;
    right:10%;
   
    margin:1rem auto;
  
    background-size:20px;
  }
  #birthday-date {
    position:relative;
    margin:1rem auto;
    border-radius: 20px !important;
    padding:12px !important;
    border:1px solid var(--elevvaTextSubtitle) !important;
    background: URL("../assets/img/calendar.png")  no-repeat   90%   ;
    background-size:18px;
  }


  .modalInfo {
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: all 1s;
    opacity: 1;
    z-index: 9999999999;
}
.tabulete{ position: relative;
    text-align: center;
    margin: auto 0;

    align-items: center;
    width: 4vmin;
    height: 4vmin
;
    border-radius: 30px;
    border: 2px solid var(--elevvaLineOnboarding);
    color: var(--elevvaTextSubtitle);
    background-color: transparent;
    opacity: 1;
    cursor: pointer;  
}
.tabulete-active{ position: relative;
    text-align: center;
    margin: auto 0;

    align-items: center;
    width: 4vmin;
    height: 4vmin
;
    border-radius: 30px;
    border: 2px solid var(--elevvabutonLogin_User);
    color: var(--elevvaTextSubtitle);
    background-color: var(--elevvabutonLogin_User);
    opacity: 1;
    cursor: pointer;  
}
.linememberstep hr{
    border-top:3px solid !important
}
.spacebutton {
    white-space: nowrap;
  max-width:20vmin !important;
  font-size:1.5vmin !important
}
s,
strike {
  text-decoration: none;
  /*we're replacing the default line-through*/
  position: relative;
  display: inline-block;
  /* keeps it from wrapping across multiple lines */
}

s:after,
strike:after {
  content: "";
  /* required property */
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid var(--elevvaTextTitle05) ;
  height: 45%;
  /* adjust as necessary, depending on line thickness */
  /* or use calc() if you don't need to support IE8: */
  height: calc(50% - 1px);
  /* 1px = half the line thickness */
  width: 100%;
  transform: rotateZ(-2deg);
}

.placeholder_onboarding{
    min-width: 30vmin;
    width: 100%;
    margin-top: 5%;
    height: 20vh;
    border: 1px solid #E2E2E2;
    padding:5%;
    background: var(--elevvaTopNav);
    color: #9DACBD;
}
.placeholder_onboarding::placeholder{
    color:var(--elevvaArrowColorCountry);
    font-size:1rem;
   
}

.nopadding {
    padding: 0vmin 0vmin !important;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

.button-position {
    position: absolute;
    bottom: 6%;
    align-self: center;
}

.form-outline{
    right: 0rem;
    top: 0rem;
    background-color: var(--elevvaTopNav);
    border: 1px solid #E2E2E2;
    /* border-radius: 20px; */
}


.spinner-container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
}

.spinner-container-welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 59rem;
}

@media (max-width:  920px) {
  

    .modalInfo .modalInfoContenidovideos {
        background-color: white;
        width: 90vw;
        padding: 6%;
        position: relative;
        border-radius: 10px;
        text-align: center;
    }

    .btnActionCancelar {
        width: 35vw !important;
        max-width: 50% !important;
        color: #ffff !important;
        border:1px solid #CB3D82;
       
    }
    .btnActionCancelar_Without_band {
        width: 60vw !important;
        max-width: 100% !important;
        color: #ffff !important;
        border:1px solid #CB3D82;
       
    }
    .btnAction {
        max-width: 50%;
        width: 35vw;
   
        display: inline-flex;
        justify-content: center;
    }
    .btnAction-only {
        max-width: 50%;
        width: 35vw;
        background-color: #CB3D82 !important;
        display: inline-flex;
        justify-content: center;
    }
    .stargolden{
        margin: auto 0;
         width: 20px;
          height: 20px
    }
  
 .imagenload{
    background-image:url("../assets/img/logosconvierte/MOB-LOADING.gif");
    background-size: cover;
    background-repeat: no-repeat;
 }
    .infoPeer .name {
        font-weight: bold;
        margin:auto;
      white-space:nowrap;  
        font-size: 2.5vmin;
    }
 
    .PanelContent .calificacionEntrenador {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .PanelContent .calificacionEntrenador img {
        width: 10px;
        height: 10px;
    }
    .PanelContent .logo img {
        width: 23%;
        min-width: 30px;
        margin: auto;
    }
    .PanelTab {
        position: absolute;
        top: 30%;
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        /* height: 25%;
        width: 5%; */
        transform: rotate(
    180deg);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 1rem 0.3rem;
    }
    .PanelTabgroup {
        position: absolute;
    
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        /* height: 25%;
        width: 5%; */
        transform: rotate(
    180deg);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 1rem 0.3rem;
    }
    .PanelContent .imgEntrenador {
       
    
        padding: 0 20%;
        justify-content: center;
    
    }
    .modalInfo .modalInfoContenidografica {
        background-color: white;
        display:flex;
        width: 80% !important;
       
        text-align: center;
       /* transform:rotate(-90deg); */
        position: relative;
        border-radius: 10px;
       
    }
    .modalInfoContenidograficagiro{
        width: 100% !important;
        height:320px;
        padding:10px;
        }
        .circulorojolesion{
            background-color:#CB3D82;
            border:1px solid #CB3D82;
            border-radius:100%;
            width: 20vw;
            height: 20vw;
            max-width: 3vmin;
            max-height: 3vmin;
              width: 10vw;
            height: 10vw;
            max-width: 3vmin;
            max-height: 3vmin;
            margin: auto 2%  auto 2% ;
        }
        .colornuevaletra{
            color:#505050 !important
        }
        .scrolldolor{
            overflow: scroll;
            height:15vh;
        }
        .scrolldolornull{
            display:none
        }
        .PanelContent .imgEntrenador1 img {
            width: calc(1rem + 4vw);
            height: calc(1rem + 4vw);
     
            margin: 0 auto;
        }
        .modalInfoContenido .title_pago{
            font-size: 4vw;
font-weight:bold;
            width: 100%;
            margin: 8% auto 3% auto;
            line-height: 100%;
            
            color: #394F65; 
        }
    }

    @media (min-width:  921px) {
        .modalInfo .modalInfoContenidovideos {
            background-color: white;
            max-width: 80vw;
            padding: 3vw;
            position: relative;
            border-radius: 10px;
            text-align: center;
        }
        .btnActionCancelar {
            max-width: 65%  !important;
            width: 45vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
            color: #ffff !important;
            border:1px solid #CB3D82 !important;
            white-space: nowrap !important;
            
        }
        .btnActionCancelar_transparent {
            max-width: 45%  !important;
            width: 35vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
            color: #CB3D82 !important;
            border:1px solid #CB3D82 !important;
            background: #ffff !important;
        }

        .btnAction {
            max-width: 45%  !important;
            width: 35vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
            /* background:#CB3D82 !important; */
        }
        .btnAction-only {
            max-width: 45%  !important;
            width: 35vw !important;
            margin:0 2.5% !important;
            display: inline-flex !important;
            justify-content: center !important;
             background:#CB3D82 !important; 
        }
        .stargolden{
            margin: 1vh 1vw;
             width: 24px;
             height: 24px
    
         
        }
        .imagenload{
            background-image:url("../assets/img/logosconvierte/MOB-LOADING.gif");
            background-size: cover;
            background-repeat: no-repeat;
        }
        .letrasconviertemosbter{
            color:#394F65;
            font-size:1rem;
            font-weight:bold;
        
        }
        
        .letrasconviertemosbtertextreservas{
            color:black;
            font-size:3.2vw;
            padding:2vh 1vw;
            
            text-align:left;
            font-weight:400;
        
        }
        .letrasconviertemosbter1{
            color:#394F65;
            padding:0 3rem 3vh 3rem !important;
         
            width:100%;
            font-size:1rem;
            font-weight:bold;
        
        }
        .letrasconviertemosbtertext{
            color:#394F65;
            font-size:0.8rem;
            padding:2vh 1vw;
            
            text-align:left;
            font-weight:400;
        
        }
        .infoPeer .name {
            font-weight: bold;
            margin:auto;
          white-space:nowrap;  
            font-size: 1vw;
        }
        .modalInfoContenido .title_pago{
            font-size: 2vw;

            width: 100%;
            margin: auto;
            line-height: 100%;
            font-weight:bold;
            color: #394F65; 
        }
        .circulorojolesion_clase{
            width:15px;
            height:15px;
            background-color:#CB3D82;
            border:1px solid #CB3D82;
            border-radius:100%;
           
        }

        .modalInfo .modalInfoContenido_clase .text {
            font-size: calc(0.5rem + 0.5vw);
            color: #7B7B7B;
            
            margin-top: 3vh;
            line-height: 110%;
        }
    .PanelContent .logo img {
        width: 17%;
        min-width: 13vmin;
        margin: auto;
    }
    .PanelContent .imgEntrenador1 img {
        width: 13vmin;
        height: 13vmin;
        object-fit: cover;
        margin: 0 auto;
    }
    .PanelContent .imgEntrenador1_f img {
        width: 4.5vmin;
   margin-right:0.7vw;
        height: 4.5vmin;

       
    }
    .PanelContent .calificacionEntrenador {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
    }
    .PanelContent .calificacionEntrenador img {
        width: 1.2rem;
        height: 1.2rem;
    }
  
   
    .PanelTab {
        position: absolute;
        top: 40%;
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        /* height: 25%;
        width: 5%; */
        transform: rotate(
    180deg);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        writing-mode: vertical-lr;
        text-orientation: mixed;
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 1rem 0.3rem;
    }
    .PanelTabgroup {
        position: absolute;
      bottom:0;
        right:10rem;
    
        z-index: 9999999999;
        background-color: #262626;
        color: white;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        border-bottom-right-radius: -25px;
        border-bottom-left-radius: -25px;
        /* height: 25%;
        width: 5%; */
       
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    
      
        cursor: pointer;
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidenav */
        padding: 0.0rem 3rem;
    }
    .PanelContent .imgEntrenador {
        margin-top: -4vh;
       
        padding: 0vw 20%;
        justify-content: center;
    
    }
    .scrolldolor{
        overflow: scroll;
        overflow-x:hidden;
        height:20vh;
    }
    .scrolldolorcoments{
        overflow: scroll;
        overflow-x:hidden;
        height:25vh !important;
        width:21vw !important;
    
    }
 
    .scrolldolornull{
        display:none
    }
    .colornuevaletra{
        font-size:0.8vw;
        color:#505050 !important;
        margin:auto 2.5vw !important;
    }
    .colornuevaletra_entre{
        font-size:0.8vw;
        color:#505050 !important;
        margin:auto 0.5vw !important;
    }
    .colornuevaletra1{
        font-size:0.9vw !important ;
        margin:auto 1vw !important;
       
    }
    .circulorojolesion{
        width:2vmin;
        height:2vmin;
        background-color:#CB3D82;
        border:1px solid #CB3D82;
        border-radius:100%;
        margin:auto 0vw auto 2.4vw !important;
    }
    .circulorojolesionperfil{
        width:2vmin;
        height:2vmin;
        background-color:#CB3D82;
        border:1px solid #CB3D82;
        border-radius:100%;
        margin:auto 0vw auto 0 !important;
    }
    .modalInfo .modalInfoContenidografica {
        background-color: white;
        display:flex;
        width: 65% !important;
 
        text-align: center;
       /* transform:rotate(-90deg); */
        position: relative;
        border-radius: 10px;
       
    }
    .modalInfoContenidograficagiro{
        width: 65vw !important;
        padding:1vw;
        height:65vh
        }
        .scrolldolorcoments_eleva{
            overflow-y: auto !important;
            overflow-x:hidden !important;
            width:100% !important;
            height:27vh !important;
          
        
        }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-4-29 12:11:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation hidding-blurred
 * ----------------------------------------
 */
 .hidding-blurred {
    animation: hidding-blurred 1s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}

@keyframes hidding-blurred {
    0% {
        filter: blur(0);
        opacity: 1;
    }
    100% {
        filter: blur(40px);
        opacity: 0;
    }
}

#containerIconStream {
    position: absolute;
    bottom: 8.5vmin;
    right: 0;
    
    margin-right:0%;
   
    z-index: 10000000000000000;

    justify-content: space-between;
    display: flex;
}

@media (min-width: 921px) {

   

.CancelarReserva {
    padding: 8%;
    max-width: 60vw;
}
.CancelarReservaClase {
    padding: 8% 0;
    max-width: 40vw;
}
.react-confirm-alert {
    min-width: 15vw;
    min-height: 20vh;
  
    border-radius: 15px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.33);
    margin: auto 5%;
}



}

@media (max-width: 481px) {
    .react-confirm-alert {
    
      
        border-radius: 15px;
        background: white;
        border: 1px solid rgba(0, 0, 0, 0.33);
        margin: auto 5%;
    }


    .CancelarReserva {
        padding: 1rem;
    }
    .CancelarReservaClase {
        margin: auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 6% 8%;
        align-items: center;
    }
  
}
.navbar-toggler-icon.filter {
    filter: sepia(0) saturate(100) brightness(100%) contrast(100%) invert(100%);
    -webkit-filter: sepia(0) saturate(100) brightness(100%) contrast(100%) invert(100%);
}