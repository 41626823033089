.claseFondo {
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    font-weight: bold;
}
.lupa_clase_eleva{
    width:8vmin;
}

.claseFondo .container .info img {
    width: 13vw;
    margin:5%;
}

.claseFondo .container {
    height: 80%;
    display: flex;
    align-items: center;
    text-align: center;
}

#claseOn .containerBGClase {
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(4px);
    -webkit-filter: blur(4px);
    position: absolute;
}

.containerComprobar {
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.377);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.container{
    margin-top:0px;
}

.containerComprobar .containerModal .title {
    font-size: calc(0.5em + 1vw);
}



.containerModal .iconTitle {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.containerModal .iconTitle img {
    width: 10%;
}








.containerFinalizar .observaciones textarea {
    max-height: 25vh;
    margin:2% auto 0% auto;
    border: 1px solid #E2E2E2;
    width: 70%;
    color:#9DACBD;
    border-radius:5px;
}
.containerFinalizar .observaciones textarea::placeholder {
    color:#9DACBD 
    }

.containerFinalizar .botones {
    text-align: center;
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 1%;
    font-size: calc(0.2em + 1vw);
}



.containerFinalizar .botones .sifin {
   
    background-color: #9EACED;
}

.containerFinalizar .botones .nofin {
    background-color: #CB3D82;
}

.containerModal .items .iconItem {
    width: 30%;
}

.containerModal .items {
    color: #969696;
    font-size: 3vmin !important;
}

.containerModal .items select {
    color: #969696;
    font-size: 3vmin !important;
}

.containerModal .items .row {
    justify-content: center;
    align-items: center;
}

.containerModal .items .row .icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.containerModal .items.scroll {
    padding:2%  5% 5% 5%; 
}

.containerModal .items.scroll .recomen {
    max-height: 40vh;
    overflow-x: scroll;
}

.containerModal .items.scroll .cancelar {
    margin-top: 5%;
    text-align: center;
    color: #394F65;
    font-weight: bold;
}
.containerModal .items.scroll .cancelarpero {
    margin-top: 5%;
    text-align: center;
    color: #394F65;
    font-weight: bold;
}

.containerModal .items.scroll .cancelar button {
    margin-top: 5%;
    color: white;
    background-color: #2A9DA5;
    border: none;
    border-radius: 30px;
    padding: 0 5%;
    
}
.containerModal .items.scroll .cancelarpero button {
    margin-top: 2%;
    color: white;
    z-index:888;
    background-color: #CB3D82;
    border: none;
    border-radius: 30px;
    padding: 1% 5%;
    
}

.containerModal .title.scroll {
    font-size: 3vmin;
}

.containerClase {
    width: 100vw;
    height: 100vh;
}

.containerClase .miVideo {
    width: 30vmin;
    height: 30vmin;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 5%;
    top: 95%;
    transform: translateY(-95%);
}
.containerClase .miVideoexpandir {
    width: 1.6rem;
    height: 1.6rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 5%;
    top: 95%;
    transform: translateY(-95%);
}

.containerClase .suVideo {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
}
.containerClase .expandirvideo {
    cursor: pointer;
    z-index: 3;
    width: 100%;
    height: 100%;
    max-width:3rem;
    max-height:3rem;
  
    
    position: absolute;
    left: 0;
    bottom: 0;
 
}

.myPanel .infoClas {
 
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2%;
}


.myPanel .infoClas_F img {
    width:2rem;
    align-self: flex-start;
    margin-right: 5%;
    margin-top: 5%;
}





.miEntrenamiento {
    color: #394F65;
}

.miEntrenamiento .titulo {
    font-weight: bold;
    font-size: calc(0.4rem + 1vw);
    text-align: center;
    padding: 3% 0;
}

.miEntrenamiento .tiempo {
    font-weight: bold;
    font-size: 2.5vmin;
    line-height: 1;
    margin: 0 auto;
    justify-content: center;
    text-align:center;
}

.miEntrenamiento .tiempo span {
    font-size: 10vmin;
}



.miEntrenamiento .rutas {
    color: #9DACBD;
}

.miEntrenamiento .rutas .itemRutina {

    display: flex;
    font-size:1.5vw;
    align-items: center;
}



.miEntrenamiento .rutas span .circle {
    width: 4vmin;
    height: 4vmin;
    background-color: #F5BFB6;
}

.miEntrenamiento .rutas .circle.descanso {
    width: 2.5vmin;
    height: 2.5vmin;
   margin:0 0.7vmin;
    background-color: #F5BFB6;
}

.miEntrenamiento .rutas .itemRutina.active {
    font-weight: bold;
}

.miEntrenamiento .rutas .itemRutina.calentamiento.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .circle.calentamiento.active {
    background-color: #9EACED !important;
}

.miEntrenamiento .rutas .itemRutina.descanso.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .circle.descanso.active {
    background-color: #9EACED !important;
}

.miEntrenamiento .rutas .circle.estiramiento.active {
    background-color: #9EACED !important;
}

.miEntrenamiento .rutas .itemRutina.estiramiento.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .itemRutina.active {
    color: #9dacbd !important;
    font-weight: bold;
}

.miEntrenamiento .rutas .circle.active {
    background-color: #9EACED !important;
}



.myPanel .textFinalizar div {
    font-weight: bold;
}

.myPanel .Finalizar button {
    background-color: #CB3D82;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 2.5vmin;
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
}


.containerFinalizar {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.777);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}



.containerFinalizar .preguntas .pregunta {
    display: grid;
    justify-items: center;
    align-content: center;
    text-align: center;
    color: #9DACBD;
    font-weight: bold;
    font-size: calc(0.4em + 1vw);
}
.swiper-button-next, .swiper-button-prev{
    color:#9DACBD !important
}

.containerFinalizar .preguntas .pregunta div {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.containerFinalizar .preguntas .pregunta .sliderPregunta {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.containerFinalizar .preguntas .pregunta .estrella {
    width: 10%;
    cursor: pointer;
}

.containerFinalizar .preguntas .pregunta .estrellas {
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.containerFinalizar .PrivateValueLabel-circle-4 {
    width: 60px !important;
    height: 60px !important;
}

.containerFinalizar .PrivateValueLabel-circle-4 {
    width: 60px !important;
    height: 60px !important;
}

.containerFinalizar .PrivateValueLabel-offset-3 {
    top: -60px !important;
}

.containerFinalizar .MuiSlider-valueLabel {
    left: auto !important;
}

@media (min-width: 1441px) {
    .PrivateValueLabel-thumb-1.PrivateValueLabel-open-2 .PrivateValueLabel-offset-3 {
        transform: scale(1.5) translateY(-10px) !important;
    }
}
@media (min-width: 100px) and (max-width:  920px) {
    .myPanel .infoClas img {
        width:1.3rem;
        align-self: flex-start;
        margin-right: 5%;
        margin-top: 8%;
    }
    .containerFinalizar .preguntas {
        height: 40vh;
    }
    .title_question_class{
        font-size: calc(0.5rem + 0.5vw);
    }
    .containerFinalizar .observaciones {
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin-top: 1%;
        font-size: calc(0.5em + 1vw);
    }
    .containerModal .subtitle {
        color: #9DACBD;
        text-align: center;
        line-height: 1;
        font-size: calc(0.5rem + 0.5vw);
        margin:  auto;
    }
    .myPanel .textFinalizar div{
        font-size: 1.5vw !important;
    
    
    }
    .myPanel .textFinalizar {
        font-size: 1.5vw !important;
    
        text-align: center;
        width: 90%;
        line-height: 1;
    }
    .miEntrenamiento .rutas span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 25px;
        min-height: 5vmin;
        margin-right: 0.4rem;
    }
    .containerModal .title {
        color: #394F65;
        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin-top: 1%;
    }
    .letradeseascancelar{
        font-size:2vw !important
    }
    .containerFinalizar .botones button {
        width: 50%;
        border: none;
        border-radius: 30px;
        padding: 0.5rem 1rem;
        color: white;
        font-weight: bold;
        margin-top:20px !important;
    }
    .myPanel .infoClas .datos .valor {
       
        font-weight: bold;
        font-size:1.4rem
    }
    .myPanel .infoClas .datos {
      
        text-align:center;
        font-size: 2.5vmin;
        font-weight: bold;
        line-height: 1;
    }
    .btnCancelNivel {
        max-width: 130px !important;
        font-size: 10px !important;
        padding:2%;
    }

    .containerModal {
        padding-bottom: 5%;
        padding-top: 2%;
        width: 65%;
        background-color: white;
        border-radius: 30px;
      
        /* overflow-y: scroll; */
    }
    .containerModal_eleva_class {
        padding: 3% 20%;
        width: 100vw;
        height: 100vh;
        background-image: url("../../assets/img/tipos_ejercicios-min.png");
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
       
    }
    .containerModal_eleva_class .items .iconItem {
        width: 30%;
    }
    
    .containerModal_eleva_class .items {
        color: #9DACBD;
        font-size: 3vmin !important;
    }
    
    .containerModal_eleva_class .items select {
        color: #9DACBD;
        font-size: 3vmin !important;
    }
    
    .containerModal_eleva_class .items .row {
        justify-content: center;
        align-items: center;
    }
    
    .containerModal_eleva_class .items .row .icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .containerModal_eleva_class .items.scroll {
        padding:2%  5% 5% 5%; 
    }
    
    .containerModal_eleva_class .items.scroll .recomen {
        max-height: 40vh;
        overflow-x: scroll;
    }
    
    .containerModal_eleva_class .items.scroll .cancelar {
        margin-top: 5%;
        text-align: center;
        color: #CB3D82;
        font-weight: bold;
    }
    .containerModal_eleva_class .items.scroll .cancelarpero {
        margin-top: 5%;
        text-align: center;
        color: #394F65;
        font-weight: bold;
    }
    
    .containerModal_eleva_class .items.scroll .cancelar button {
        margin-top: 5%;
        color: white;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 0 5%;
        
    }
    .containerModal_eleva_class .items.scroll .cancelarpero button {
        margin-top: 2%;
        color: white;
        z-index:888;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 2% 5%;
        
    }
    
    .containerModal_eleva_class .title.scroll {
        font-size: 4vmin;
        color:#9DACBD;
    text-align:center    }
  
 
}
@media (min-width: 921px)  {
    .myPanel .infoClas img {
        width:2.5rem;
        align-self: flex-start;
        margin-right: 5%;
        margin-top: 8%;
    }
    .containerFinalizar .preguntas {
        height: 30vh;
    }
    .title_question_class{
        font-size: calc(1rem + 0.5vmin);
    }
    .containerFinalizar .observaciones {
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin-top: 1%;
        height:30vh;
        font-size: calc(1rem + 0.5vmin);
    }
    .containerModal .subtitle {
        color: #9DACBD;
        text-align: center;
        line-height: 1;
        font-size: calc(1rem + 0.5vmin);
        margin:  auto;
    }
    .myPanel .textFinalizar {
        font-size: calc(0.1rem + 1vw);
    
        text-align: center;
        width: 90%;
        line-height: 1;
    }
    .miEntrenamiento .rutas span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 25px;
        min-height: 4vmin;
        margin-right: 0.4rem;
    }
    .containerModal_eleva_class .items.scroll .cancelar {
        margin-top: 5%;
        text-align: center;
        color: #394F65;
        font-weight: bold;
    }
    .containerModal_eleva_class .items.scroll .cancelarpero {
        margin-top: 5%;
        text-align: center;
        color: #394F65;
        font-weight: bold;
    }
    
    .containerModal_eleva_class .items.scroll .cancelar button {
        margin-top: 5%;
        color: white;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 0 5%;
        
    }
    .containerModal_eleva_class .items.scroll .cancelarpero button {
        margin-top: 2%;
        color: white;
        z-index:888;
        background-color: #CB3D82;
        border: none;
        border-radius: 30px;
        padding: 2% 5%;
        
    }
    .containerModal_eleva_class .items.scroll .recomen {
        text-align:center;
        margin:2% auto;
        color: #9DACBD;
       
        
    }
    
    .containerModal_eleva_class .title.scroll {
        font-size: 3vmin;
        color:#394F65;
        font-weight:bold;
    text-align:center    }
  
 

    .containerModal_eleva_class {
        padding:6% 30%;
        width: 100vw;
        height: 100vh;
        font-size: 2.5vmin;
     
        background-image: url("../../assets/img/tipos_ejercicios-min.png");
        background-position: center;
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -o-background-size: cover;
       
    }
    .containerModal .title {
        color: #394F65;
        font-size: calc(.1rem + 2vw);
        font-weight: bold;
        text-align: center;
        display: flex;
        justify-content: center;
        line-height: 1;
        margin-top: 1%;
    }
    .containerFinalizar .botones button {
        /* width: 35%; */
        border: none;
        border-radius: 30px;
        padding: 0.5rem 0.7rem;
        color: white;
        font-weight: bold;
        margin-top:20px !important;
        font-size: calc(0.8rem + 0.5vmin);
        min-width:15rem;
    }
    .letradeseascancelar{
        font-size:1.5vw !important
    }
    .lineclas_clase{
        border-top:1px solid #BEBEBE;
        width:30%;
        opacity:0.5;
        padding:0 6vw;
        z-index:1;
    }
    .edadCliente{
        font-size:0.8vw;  
    }
    .nivelCliente{
        font-size:0.8vw;
    }
    .myPanel .infoClas .datos .valor {
   font-size:2rem;
   margin-left:1.0rem;
        font-weight: bold;
    }
    .myPanel .infoClas .datosClass .valor {
        font-size:4rem;
        
             font-weight: 600;
         }
    .myPanel .infoClas .datos {
        color: #394F65;
      
        font-weight: bold;
        
        line-height: 1;
        text-align:center;
        display:flex;
        align-items:center;
    }
    .myPanel .infoClas .datosClass {
        color: #9DACBD;
      
        font-weight: bold;
        
        line-height: 1;
        text-align:left;
        display:flex;
       
    }
    .miEntrenamiento .rutaContainer {
        height: 50%;
        overflow-y: scroll ;
        margin: 4%;
        font-size: calc(0.5rem + 0.5vw);
    }
     .Finalizar1 button {
        background-color:#CB3D82;
        border: none;
        color: white;
        justify-content: center;
        display:flex;
        font-weight: bold;
        font-size: 2.5vmin;
        margin:auto;
        border-radius: 30px;
        padding: 0.5rem 1.5rem;
    }
     .Finalizar1 .btnFinalizar {
        background-color:#CB3D82;
        border: none;
        color: white;
        justify-content: center;
        display:flex;
        font-weight: bold;
        font-size: 2.5vmin;
        margin:auto;
        border-radius: 30px;
        padding: 0.5rem 1.5rem;
    }
     .Finalizar1 .btnFinalizar_clase_stream {
        background-color:#CB3D82;
        border: none;
        color: white;
        justify-content: center;
        display:initial;
        font-weight: bold;
        font-size: 2.5vmin;
        margin:auto;
        border-radius: 30px;
        padding: 0.5rem 1.5rem;
    }
    .containerModal {
        padding: 4% 1%;
      
        width: 40%;
        background-color: white;
        border-radius: 30px;
       
      
       
        /* overflow-y: scroll; */
    }
    .btnCancelNivel {
        max-width: 190px !important;
        font-size: 16px !important;
    }
 
}



.miEntrenamiento .rutaContainer {
    height: 50%;
    overflow-y: scroll ;
    display: flex;
    justify-content: center;
    margin: 4%;
    font-size: calc(0.5rem + 0.5vw);
}
/* @media (min-width: 901px) {
    .miEntrenamiento .rutaContainer {
        height: 70%;
     
        margin: 4%;
        font-size: calc(0.5rem + 0.5vw);
    }
} */


.iconsParentClase {
    display: flex;
    justify-content: space-evenly;
    width: 95%;
    margin: auto;
    align-items: flex-start;
}

.iconsAfterClase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.iconsAfterClase img {
    width: 6vmin;
    margin-left: 5px;
}

.iconHeaderWait {
    width: 8vmin;
    margin: 0 auto 10px auto;
    display: flex;
    justify-content: center;
}



.btnCancelNivel.activo {
    background-color: #CB3D82 !important;
    color: white;
}

.containerFinalizar .swiper-button-next:after, .containerFinalizar .swiper-button-prev:after{
    font-size: 23px !important;
}

.containerFinalizar .swiper-button-next, .containerFinalizar .swiper-button-prev {
    height: 23px !important;
}

.PanelContent .calificacionEntrenador {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.PanelContent .logo img {
    width: 23%;
    min-width: 30px;
    margin: auto;
}
.PanelTab {
    position: absolute;
    top: 30%;
    z-index: 9999999999;
    background-color: #262626;
    color: white;
    /* height: 25%;
    width: 5%; */
    transform: rotate(
180deg);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    cursor: pointer;
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    padding: 1rem 0.3rem;
}
.PanelTabgroup {
    position: absolute;

    z-index: 9999999999;
    background-color: #262626;
    color: white;
    /* height: 25%;
    width: 5%; */
    transform: rotate(
180deg);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    cursor: pointer;
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    padding: 1rem 0.3rem;
}
.PanelContent .imgEntrenador {
   

    padding: 0 20%;
    justify-content: center;

}

/* The side navigation menu */

.PanelContent {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    background-color: #FDF1E8;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */

    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    max-width: 350px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
   
    padding-bottom: 15px;
 
    z-index: 9999999999;
    color: #394F65;
}
.PanelContentgroup {
    border-top:3px #CB3D82 solid ;
    height: 0%;
    /* 100% Full-height */
    width: 100%;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    bottom: 0%;
    /* Stay at the top */
    background-color: #FDF1E8;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
   
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
    
    display: flex;
    justify-content: space-between;
    flex-direction: unset;
    
    z-index: 9999999999;
    color: white;
}

.PanelContent.right {
    right: 0;
    border-left: 2px solid #CB3D82;
}
.PanelContent.right1 {
    right: 0%;
    border-left: 2px solid #CB3D82;
}

.PanelContent.left {
    left: 0;
    border-right: 2px solid #CB3D82;
    /* display: grid;
    justify-items: center;
    align-content: space-evenly; */
    align-items: center;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */



.PanelContent .logo {
    width: 100%;
    display: flex;
}







.PanelContent .imgEntrenador .infoPeer {
 
    display: flex;
    flex-direction: column;
    justify-content: center;
}







/*Reloj y mensaje de espera clase*/

.clock {
    position: absolute;
    width: 100px;
    height: 100px;
    border: 5px solid #CB3D82;
    border-radius: 50%;
    left: 50%;
    top: 20%;
    background: #fefbf2;
    transform: translate(-50%, -50%);
}

.clock::before {
    position: absolute;
    content: '';
    height: 36px;
    width: 5px;
    background: #CB3D82;
    top: 50%;
    left: calc(50% - 2.5px);
    border-radius: 5px;
    animation: spin 6s linear infinite;
    transform-origin: top;
}

.clock::after {
    position: absolute;
    content: '';
    height: 26px;
    width: 5px;
    background: #CB3D82;
    top: 50%;
    left: calc(50% - 2.5px);
    border-radius: 5px;
    animation: spin 72s linear infinite;
    transform-origin: top;
}

@keyframes spin {
    0% {
        transform: rotate(-180deg)
    }
    100% {
        transform: rotate(180deg)
    }
}

#containerClock::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #CB3D82;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

#containerClock::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #394F65;
    left: 50%;
    top: calc(20% - 44px);
    z-index: 2;
    transform: translateX(-50%);
    box-shadow: 40px 43px 0 0 #394F65, -42px 43px 0 0 #394F65, 0 82px 0 0 #394F65;
}



.react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}

/* Panel para la clase */



.PanelTabgroup.left {

    border-radius: 10px 0px 0px 10px;
    margin-left: 0px;
    left: 0;
}
.PanelTab.left {

    border-radius: 10px 0px 0px 10px;
    margin-left: 0px;
    left: 0;
}

.PanelTab.right {
    border-radius: 0px 10px 10px 0px;
    margin-right: 0px;
    right: 0;
}
.PanelTab.right.otro {
    border-radius: 0px 10px 10px 0px;
    margin-right: 0px;
    right: 0;
    top:20% !important;
}

.PanelTab img {
    width: 15px;
    height: 15px;
}
.PanelTabgroup img {
    width: 15px;
    height: 15px;
}

.PanelTab .text {
    font-size: calc(0.5em + 1vw);
    margin-bottom: 10px;
}
.PanelTabgroup .text {
    font-size: calc(0.5em + 1vw);
    margin: 0.5rem;
}