
    .buttonIndividualTypesCom{
    border:2px solid #F4F0ED;
    background:#F4F0ED;
   border-radius:20px;
   font-size:0.7rem;
   color:#394F65;
   padding:2% 3.5%;
   width:40%;
   margin:0 2% 0 0;
   
}
.resplandor {
 
    
    animation-duration: 0.5s;
    animation-name: changewidth;
    animation-iteration-count: infinite;
    animation-direction: alternate;;
}
@keyframes changewidth {
    from {
        box-shadow: 0px 0px 15px #CB3D82;
    }
    to {
        box-shadow: 0px 0px 0px #CB3D82;
    }
}

@media (max-width: 992px)  {
    .letraPequeñaother{
        font-size: 1rem!important
    }
}
@media (min-width: 993px)  {
    .letraPequeñaother{
        font-size: 1.6vmin !important
    }
}


.letraPequeñaotherzumba{
    font-size: 1.5vmin !important
}
.figurePartsInicio{
    width: 20vmin
}
.letraBody{
    font-size: 1.4vmin !important;
    color:var(--elevvaTextTitle);
    font-weight:500;
}
.letraBodyInside{
    font-size: 1.3vmin !important;
    color:var(--elevvaTextTitle);
    font-weight:500;
}
.letraBodyInsidemoresmall{
    font-size: 1.1vmin !important;
    color:var(--elevvaTextTitle);
    font-weight:500;
}

.letraPequeñaCardTitle{
    font-size: 2.6vmin !important
}

.option-page{
    color:var(--elevvaTextTitle);
    cursor:pointer;
    align-items:center;
    width: 100%;
    padding:0% 8%;
   
}
.elevation-2 {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, .05), 0 3px 4px 0 rgba(0, 0, 0, .05), 0 1px 5px 0 rgba(0, 0, 0, .05)
}
.elevation-12 {
	box-shadow: 0 12px 16px 2px rgba(0, 0, 0, .05), 0 5px 22px 4px rgba(0, 0, 0, .05), 0 6px 13px 0 rgba(0, 0, 0, .05)
}
.elevation-18 {
	box-shadow: 0 18px 25px 3px rgba(0, 0, 0, .05), 0 7px 33px 6px rgba(0, 0, 0, .05), 0 9px 11px 0 rgba(0, 0, 0, .05)
}
.option-page.active{
    color:var(--elevva);
    background: var(--backgroundOnboardingContain)
}
.option-page.active .letraPequeña{
    color:var(--elevva) !important;

}
.option-page:hover{
    color:var(--elevva);

}
.option-page:hover .letraPequeña{
    color:var(--elevva) !important;

}

.bi-plus-circle-fill::before {
    font-weight:90 !important;
}
.howcenteritems{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.btnarrowPanel{
    width:3.5vmin !important;
    height:3.5vmin !important;
    border-radius:7px !important;
}
.btnarrowPanelColor{
    width:4vmin !important;
    height:4vmin !important;
    border-radius:7px !important;
    border:1px solid var(--backgroundOnboardingObjective) !important;
    background:var(--backgroundOnboardingObjective) !important;
}
.skill{
    width:11.5vmin;
    height:11.5vmin;
   
    position:relative;
}
.outer{
    width:11.5vmin;
    height:11.5vmin;
    border-radius:50%;
    padding:10%;
    box-shadow:6px 6px 10px -1px rgba(0,0,0,0.15), -6px -6px 10px -1px rgba(255,255,255,0.7)
}
.inner{
    width:100%;
    height:100%;
    border-radius:50%;
    display:flex;
    justify-content: center;
    align-items:center;
    box-shadow:inset 4px 4px 6px -1px rgba(0,0,0,0.2),
    inset -4px -4px 6px -1px rgba(255,255,255,0.7),
    -0.5px -0.5px 0px rgba(255,255,255,1),
    0.5px 0.5px 0px rgba(0,0,0,0.15),
    0px 12px 10px rgba(0,0,0,0.05)
}
#number{
    font-weight:600;
    color:#555;

}
.progress-bar {
    background-color:var(--barProgress) !important;
   
}
#circleRound{
    fill:none;
    stroke:url(#GradientColor);
    stroke-width:10%;
    stroke-dasharray:290%;
    stroke-dashoffset:0%;
    animation:anim 2s linear forwards;
}
#svgt{
position: absolute;

top:0;
left:0;
}
.direcccionately{
    display:flex;
    flex-direction:column;
    justify-content: center;
}
.direcccionately_other{
    display:flex;
    flex-direction:column;
    justify-content: inherit;
}
.letrapequeñaProfile{
    font-size:2.3vmin;
    color:var(--elevvaTexttitleLow);
    text-align:center
}
.letrapequeñaProfilePlan{
    font-size:3vmin;
    color:var(--elevvaTexttitleLow);
    text-align:center
}
.letrapequeñaProfileDown{
    font-size:1.0vmin;
    color:var(--elevvaTextTitle);
    text-align:center;
}
.letrapequeñaProfileDown_other{
    font-size:1.0vmin !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    color:var(--elevvaTextTitle);
    text-align:center;
   
}
.letrapequeñaProfileDown_otherPlan{
    font-size:calc(0.6rem + 0.1vmin) !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    color:var(--elevvaTextTitle);
    text-align:center;
   
}
.swiper-button-next, .swiper-button-prev {
    color:var(--elevvaTextTitle) !important;
    width: 3vmin !important;
    height:3vmin !important;
    border-radius:50% !important;
    border:1px solid var(--white) !important;
    background:var(--white) !important
    
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-size:1.0vmin !important;
  font-weight:bold !important;
}
@keyframes anim {
    100%{
        stroke-dashoffset:var(--percentageCircularBar);
        /* stroke-dashoffset:150%; */
    }
}
.sliderNetFlixvideo video{
    border-radius:15px;
   
}
.resplandor-sliderNetFlixvideo video{
    animation-duration: 0.5s;
    animation-name: changewidth2;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
@keyframes changewidth2 {
    from {
        box-shadow: 0px 0px 11px rgb(203,61,130,1);
       
    }
    to {
        box-shadow: 0px 0px 0px rgb(203,61,130,1);
       
    }
}
.marginal_zero{
    margin:0 !important;
    padding:0.3vmin !important;
    max-width:12vmin !important;
}
.otherButon_with_icon{
    max-width:65% !important;
    font-size:2vmin !important;
}
.position-image-quicly{

}

.btnmax {
    max-width: 265px !important;
}

.react-calendar__tile--active {
    background: #cb3d82 !important;
    border-radius: 18px !important;
}

.noMargin {
    margin: -1rem auto;
}

/* color de clase */
.color-reserva {
    background-color: #ffe9ca;
}

/* overflow*/ 

.profe {
    height: calc(100vh - 50vmin);
    overflow-y: auto;
}

/* Color SeleccionarProfe*/ 

.border-color {
    border-radius: 20px;
    /* border: 1px solid #000; */
}