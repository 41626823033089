.growingTutorial{ 
   zoom:105% !important;


transition-duration: 0.4s;
filter: drop-shadow(0.3rem 0.3rem 0.3rem rgba(0, 0, 0, 0.5)); 

}

@media (max-width: 1600px) {
.videosTutotialalto {
  padding: 0 
}
}

@media (min-width: 1601px) {
  .videosTutotialalto {
    padding:8% 0 
  }
  }

 