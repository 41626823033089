@media (min-width: 100px) and (max-width: 920px) {
    .containerBackgroundCreateuserre {
        position: relative;
       
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    
.button_w_h_back{
    width:4rem;
     height:4rem
}


    .question_Class{
        font-weight:bold;
       
        color:var(--elevvaTextTitle) !important;
    }
    .answer_Class{
        font-weight:400;
  
        color:var(--elevvaTextTitle) !important;
    }
    .searchEjercicio .input_searchvideossearch {
        border:1px solid #9DACBD !important;
        border-radius: 20px;
        max-width: 250px;
       right:1%;
       outline-offset: 0px;
   
        background-color:white !important;
        font-size: 1rem;
            width: 100%;
            padding: 9px 4px 9px 40px;
             background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;

    }
    .contenedorInfoCompanie{
        height: 30%;
        display:flex;
    width: 90%;
    margin-top: -25vmin !important;
    overflow: hidden;
    }
    .VerReserva .contenedorInfoCompanie .title {
        font-weight: bold;
        color: #394F65;
        font-size: 5vw;
    }
    .VerReserva .contenedorInfoCompanie .titulo {
      
        display: flex;
      
       
        align-items: flex-start;
        color: #394F65;
    }
    .VerReserva .contenedorInfoCompanie .titulo span {
        font-weight: bold;
        margin-left: 1rem;
        
        display: flex;
        padding: 1vh 0;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .containerBackgroundCreateuser{
        position: relative;
       
      
        background-size: cover;
        width: 100%;
        height:100%;
      
   
        
        background-repeat: no-repeat;
      
        
     }
    .clasebutton2{
       
       width: 30%;
        background:#44596E;
        color:white;
        padding:2.3vmin 1vmin !important;
        border:1px solid #44596E;
        font-size:2.7vmin;
        font-weight:bold;
        border-top-left-radius:10px;
        border-top-right-radius:10px;
      } 
   
    .butonvideobanner_two{
        font-weight:600  !important;
        padding: 1% 1.7% !important;
        background:#ffff !important;
        border:1px solid #ffff !important;
        color:#CB3D82 !important;
        max-width:500px !important;
        /* max-width:40vmin !important; */
        /* font-size:2.5vmin !important; */
      } 
    .butonvideobanner{
        font-weight:600  !important;
        
        /* max-width:40vmin !important; */
        /* font-size:2.5vmin !important; */
      } 
    .glosario .btnPlayVideo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .letrabuttontutorial{
        font-size:2.8vmin !important;
        padding:1.5vmin 1vmin !important;
        
        font-weight: 500 !important;
        max-width: 100% !important;
      }
    
    .containerVideo {
        position: fixed;
        width: 100vw;
        padding:2vh 2vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 3;
    }
    
    .containerVideo .videoEmbed {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 4;
        text-align: center;
    }
    
    .containerVideo .videoEmbed iframe {
        max-width: 560px;
        min-height: 315px;
        width: 100%;
    }
    .containerVideopayu {
        position: fixed;
        width: 100vw;
        padding:2vh 2vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 3;
    }
    
    .containerVideopayu .videoEmbedpayu {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 4;
        text-align: center;
    }
    
    .containerVideopayu .videoEmbedpayu iframe {
        max-width: 550px;
        min-height: 515px;
        width: 100%;
    }
    
    .breadcum {
        color: #9DACBD;
        /* font-family:Poppins-Regular; */
        font-weight: 400;
        font-size: calc(0.5rem + 1vw);
    }
    .CompletarFooter1 {
        position: sticky !important;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: space-around !important;
        width: 100%;
        color: white;
        height: 7vh;
     
        padding: 10;
        border: 00 !important;
        cursor: pointer;
    }
    

.searchEjercicio  .search_icon {
    height: 5vh;
    margin-top:0.7vh;
  
    margin-left: 2vw !important;
}
.input_search+.search_icon {
    position: absolute;
    left: 0;
    padding: 5px 10px;
}
   
    .searchEjercicio select option {
       
       margin:auto;
       border: 1px solid #9EACED !important;
       font-weight:bold;
       font-size: 0.7rem;
    }
    .searchEjercicio .input_search {
        border: 1px solid var(--elevvaTextSubtitle);
        border-radius: 25px;
        max-width: 500px;
        width: 80%;
        
        padding-left: 13vw !important;
        padding-top:0.1rem;
        font-size: 1rem;
        height: 6vh;
       
        
    } 
    .searchEjercicio .coregir_resr {
        border: 1px solid #9DACBD;
        border-radius: 25px;
        max-width: 500px;
        width: 100%;
        color:#394F65;
        margin-left:0vw !important;
        margin-right:1vw !important;
        padding-left: 15vw !important;
        padding-top:0.1rem;
        font-size: 1rem;
        height: 6vh;
       
        
    } 
    .searchEjercicio .ejercicios_c {
        border: 2px solid #E5E9ED;
        border-radius: 25px;
        max-width: 500px;
        width: 100%;
        margin-left:1vw;
        margin-right:1vw;
      
        padding-top:0.1rem;
        font-size: 1rem;
        height: 5vh;
       
        
    } 
    .searchEjercicio select {
        width: 100%;
        height: 5vh;
        border: 1px solid #9EACED !important;
        border-radius: 22px;
        padding: 0.5vh 5rem;
        color:#9EACED !important;
        font-weight: bold;
        font-size: 4vw;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("../../assets/assets/arrowdown.png") ;
        background-position: calc(90% - 3vw) !important;
        background-size: 4vw;
        background-repeat: no-repeat;
        max-width: 300px;
    }
    .CompletarFooter1 .siguiente1 {
        color: white;
        border-radius:25px;
        background-color: #9EACED;
        font-size:3vw;
      
        
    }
    .input_search+.search_icon {
        position: absolute;
        left: 0;
        padding: 5px 50px;
    }
    .coregir_resr+.search_icon {
        position: absolute;
        left: 0;
        padding: 5px 20px;
    }
    .ejercicios_c+.search_icon {
        position: absolute;
        left: 0;
        opacity:0.4;
        padding: 5px 20px;
    }
    .searchejercicios .searchEjercicio{
        margin: auto;
        display:flex;
        position: relative;
        width:80%;
        max-width: 350vw;
        max-height:70vh;
    }
    .searchejercicios .searchEjercicioselect{
        margin: auto;
      
        display:flex;
        position: relative;
        width:100%;
        max-width: 70vw;
        max-height:70vh;
    }
    .searchEjercicio {
        margin: auto;
        display:flex;
        position: relative;
        width:100%;
        padding:5% 0; 
        background:var( --backgrounColorBar);
        height:50%;
        max-width: 350vw;
        max-height:20vh !important;
    }
    .navFooterejercicios1 {
        padding-bottom:5vh;
        border:1px solid gray;
        width: 70%;
        position: sticky !important;
        bottom: 0;
        background-color: white;
       
        padding: 0 !important;
        padding-top: 1vh !important;
        padding-bottom: 1vh !important;
      margin:auto;
    
    }

    .bulletsVideos {
        position: absolute;
        bottom: 3% !important;
        display:flex;
        justify-content: center;
        
        z-index: 1;
    }
    .bulletsVideos .swiper-pagination-bullet {
        width: 20px !important;
        height: 20px !important;
        background-color: transparent;
        border: 1px solid #CB3D82;
        opacity: 1;
    }
    .bulletsVideos .swiper-pagination-bullet.active {
        background-color: #CB3D82;
        opacity: 1;
    }
    .bulletsQuestions {
        position: absolute;
        bottom: 4% !important;
        display:flex;
        justify-content: center;
        
        z-index: 1;
    }
    .bulletsQuestions .swiper-pagination-bullet {
        width: 15px !important;
        height: 15px !important;
        background-color: transparent;
        border: 1px solid var(--backgroundOnboardingObjective);
        opacity: 1;
    }
    .bulletsQuestions .swiper-pagination-bullet.active {
        background-color: var(--backgroundOnboardingObjective);
        opacity: 1;
    }
}
    @media (min-width: 921px) {
        .button_w_h_back{
            width:calc(2.2rem + 0.7vw);
             height:calc(2.2rem + 0.7vw)
        }
        .searchEjercicio {
            
            padding:5% 0; 
            background:var( --backgrounColorBar);
           
         
        }
        .question_Class{
            font-weight:bold;
            font-size:calc(0.4rem + 0.4vw) !important;
            color:var(--elevvaTextTitle) !important;
        }
        .answer_Class{
            font-weight:400;
            font-size:calc(0.4rem + 0.4vw) !important;
            color:var(--elevvaTextTitle) !important;
        }
        
        .slidersNetflixReservas .sliderNetFlixvideorozo {
            /* min-height: 160px; */
            width: 240px !important;
            height: 100% !important;
            font-size: 1rem;
            text-transform: capitalize;
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
            word-wrap: break-word;
            cursor: pointer;
        }
        .slidersNetflixReservas .sliderNetFlixvideorozo .content img:hover {
            width: 245px !important;
            border: 1px solid rgba(158, 172, 237, 1);
            -webkit-transition: 0.2s;
            -o-transition: 0.2s;
            -moz-transition: 0.2s;
            transition: 0.2s;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
           
        }
        .sliderNetFlixvideocomidas .content{
            width: 182px;
            height: 276px;
            
            /* width: 193px;
            height: 290px; */
        }
      
        .slidersNetflixReservas .sliderNetFlixvideocomidas {
            width: 182px;
            height: 276px;
            font-size: 1rem;
            text-transform: capitalize;
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
            word-wrap: break-word;
            cursor: pointer;
            border-radius:20px;
            overflow: hidden;
            
        }

        .sliderNetFlixvideocomidas:hover{
             zoom:100.2% !important;
           
             background-color: #f2f2f2;
             border-radius: 20px;
             box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.27);
   }
       
        .bulletsVideos {
            position: absolute;
            bottom: 10% !important;
            left: 50% !important;
            z-index: 1;
        }
        .bulletsVideos .swiper-pagination-bullet {
            width: 2vmin !important;
            height: 2vmin !important;
            background-color: transparent;
            border: 1px solid #CB3D82;
            opacity: 1;
        }
        .bulletsVideos .swiper-pagination-bullet.active {
            background-color: #CB3D82;
            opacity: 1;
        }
        .bulletsQuestions {
            position: absolute;
            bottom: 10% !important;
            display: flex;
    justify-content: center;
         
            z-index: 1;
        }
        .bulletsQuestions .swiper-pagination-bullet {
            width: 2vmin !important;
            height: 2vmin !important;
            background-color: transparent;
            border: 1px solid var(--backgroundOnboardingObjective);
            opacity: 1;
        }
        .bulletsQuestions .swiper-pagination-bullet.active {
            background-color: var(--backgroundOnboardingObjective);
            opacity: 1;
        }
        .slidersNetflixReservasNew .sliderNetFlixvideoNew video {
            object-fit: initial !important;
        }
        .sliderPublicidadnew  {
            margin:auto 0% !important; 
        }
         .sliderNetFlixvideolo .content{
            width: 250px;
            height: 325px;
        }
        .slidersNetflixReservas .sliderNetFlixvideolo {
            min-height: 350px;
            width: 250px !important;
            font-size: 1rem;
            text-transform: capitalize;
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
            word-wrap: break-word;
            cursor: pointer;
        }
         .sliderNetFlixvideoloco .content{
            width: 233px;
            height: 353px;
            /* width: 193px;
            height: 290px; */
        }
       
        .slidersNetflixReservas .sliderNetFlixvideoloco {
            width: 233px;
            height: 353px;
            font-size: 1rem;
            text-transform: capitalize;
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
            word-wrap: break-word;
            cursor: pointer;
        }
        .sliderNetFlixvideoloco .content img:first-child:hover{
            width: 235px;
            height: 360px;
            font-size: 1rem;
            border-radius:20px;
            text-transform: capitalize;
            background-position: center;
            -webkit-background-size: cover;
            -moz-background-size: cover;
            background-size: cover;
            background-repeat: no-repeat;
            word-wrap: break-word;
            cursor: pointer;
            transition: 0.7s;
            box-shadow:0px 0px 15px -4px rgba(0,0,0,0.50);
            border:	0.5px solid rgba(0, 0, 0,  0.1);
            border-radius:	20px;
        }
        .sliderNetFlixvideocomidas .content img:last-child{
            pointer-events: none;
        }
        .swiper-button-prev-banners:after,.swiper-button-next-banners:after {
            font-size:15px !important;
            color:white;
                }
               .swiper-button-next-banners{
                position:relative !important;
                background:#9EACED !important;
                margin:auto 3vmin auto 0;
                width:5vmin !important;
                height:5vmin !important;
                top: 0 !important;
                border-radius:50% !important;
                border:1px solid #9EACED;
                }
                .swiper-button-prev-banners{
                    position:relative !important;
                    background:#9EACED !important;
                    margin:auto 0 auto 3vmin;
                    width:5vmin !important;
                    height:5vmin !important;
                    top: 0 !important;
                    border-radius:50% !important;
                    border:1px solid #9EACED;
                }
                .swiper-button-prev-banners:after {
                    content: 'prev';
                    font-family: swiper-icons;
                    font-size: 15px !important;
                    font-variant: initial;
                    height: 100%;
                    justify-content: center;
                    text-transform: none!important;
                    letter-spacing: 0;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                }
                .swiper-button-next-banners:after {
                    content: 'next';
                    font-family: swiper-icons;
                    font-size: 15px !important;
                    font-variant: initial;
                    height: 100%;
                    cursor: pointer;
                    justify-content: center;
                    text-transform: none!important;
                    letter-spacing: 0;
                    display: flex;
                    align-items: center;
                }
    
    
    
    
     
        
     
        
        .searchEjercicio .input_searchvideossearch {
            border:1px solid #9DACBD !important;
            border-radius: 20px;
            max-width: 250px;
           right:1%;
           outline-offset: 0px;
       
            background-color:white !important;
            font-size: 1rem;
                width: 100%;
                padding: 9px 4px 9px 40px;
                 background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    
        }
        .containerBackgroundCreateuser{
            position: relative;
            background-position: center;
          
            
            width: 100%;
            height:100%;
       
            
            background-repeat: no-repeat;
          
            
         }
        .butonvideobanner_two{
            font-weight:500  !important;
            padding: 2% 0vmin !important;
            background:#ffff !important;
            border:1px solid #ffff !important;
            color:#CB3D82 !important;
            max-width:20vmin !important;
            font-size:1.5vmin !important;
          } 
        .butonvideobanner{
            font-weight:500  !important;
            
            max-width:15vmin !important;
            font-size:1.5vmin !important;
          } 
        .letrabuttontutorial{
            font-size:2.0vmin !important;
            font-weight: 500 !important;
            max-width: 18vmin !important;
            padding:5px !important
          }
        .ubicarfel{
            width:100%;
            
        }
        .flechaenvideos{
            display:none;
         position:absolute;
         justify-content: flex-end;
         left:0%;
         top:35%;
         align-items:left;
            margin-left:95% !important;
        }
    
        .tablaIngresos{
            margin:2% 10% 2% 16%;
        }
        .glosario .btnPlayVideo {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        
        .containerVideo .videoEmbed {
            position: absolute;
            top: 36%;
            left:60%;
            max-width: 30vw;
            z-index: 4;
            width: 100%;
            text-align: center;
        }
        
        .containerVideo .videoEmbed iframe {
            max-width: 960px;
            min-height: 40vh;
            width: 100%;
        }
        
        .breadcum {
            color: black;
            font-weight: 500;
            float: right;
            margin-right:3vw;
 text-align:right;
            font-size: calc(1rem);
        }
     
        .anteriorprev, .siguientenext,.numerosmedio {
font-size:0.7rem
        }
        .CompletarFooter1 {
            position: absolute !important;
            top: 85%;
            display: flex;
            align-items: center;
            justify-content: space-around !important;
            width: 100%;
            color: white;
            height: 7vh;
         
            padding: 10;
            border: 00 !important;
            cursor: pointer;
        }
        .navFooterejercicios1 {
            padding-bottom:5vh;
            border:1px solid gray;
            width: 30%;
            position: absolute !important;
            top: 90% !important;
            background-color: white;
           
            padding: 0 !important;
            padding-top: 1vh !important;
            padding-bottom: 1vh !important;
          margin:auto;
        
        }
        
.searchEjercicio .search_icon {
    height: 5vh;
    margin-top:0.7vh;
    margin-left: 1.2vw !important;
}
.searchEjercicio .search_iconEjerci {
    height: 5vh;
    margin-top:0.7vh;
    margin-left: 1.2vw !important;
}
.searchEjercicio .search_iconEjerci_videos {
    position: absolute;

left:1%;
position: inherit;
width:2vmin;

    
}
.searchEjercicio .search_iconEjerci1 {
    height: 5vh;
    margin-top:0.7vh;
    margin-left: 1.2vw !important;
}
.input_search+.search_icon {
    position: absolute;
    left: 14%;
    padding: 2px;
}
.input_search+.search_iconEjerci {
    position: absolute;
    left: 0%;
    padding: 5px;
}
.input_search+.search_iconEjerci1 {
    position: absolute;
    left: 15%;
    padding: 5px;
}
   

        .searchejercicios .searchEjercicioselect{
         
            display:flex;
        
            width:30% !important;
           
            max-height:70vh;
        }
        .searchejercicios .searchEjercicio{
        
            display:flex;
            position: relative;
            width:100%;
            max-width: 350vw;
            max-height:70vh;
        }
        .glosariopreguntas{
            width:95% !important;
            margin:0 auto;
        }
        .glosariopreguntasvideo{
            width:35% !important;
            position:absolute;
        }
        .glosariopreguntasvideomitad{
            /* width:30vw !important; */
            position:relative;
            left:53%;
        }
  
   
        .searchEjercicio select option {
            color:#9DACBD !important;
           margin:auto;
      
           font-weight:bold;
           font-size: 1rem;
        }
        .searchEjercicio select {
            width: 15vw;
            height: 5vh;
            border: 1px solid #9DACBD !important;
            border-radius: 22px;
            padding: 0.3rem 2vw 0.3rem 0vw;
            margin:1vh 0 ;
            color: #9DACBD;
            font-weight: bold;
           
            margin: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            
            -webkit-appearance: none;
            -moz-appearance: none;
            background-image: url("../../assets/assets/arrowdown.png");
            background-position: calc(100% - 3vw);
            background-size: 1vw;
            background-repeat: no-repeat;
            max-width: 400px;
        }
        .CompletarFooter1 .siguiente1 {
            color: white;
            border-radius:25px;
            background-color: #9EACED;
           
            font-weight:bold
            
        }
        .searchEjercicio .input_search {
            border:2px solid #9DACBD;
            border-radius: 25px;
            background-color: var(--white);
            width: 90%;
            padding-left: 10% !important;
            font-size: 1rem;
            height: 6vh;
        }
        /* .searchEjercicio .input_searchvideos {
            border:1px solid #9DACBD !important;
            border-radius: 20px;
            max-width: 140px;
           right:1%;
           outline-offset: 0px;
       
            background-color:white !important;
            font-size: 1rem;
                width: 100%;
                padding: 9px 4px 9px 40px;
                 background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
    
        } */
      
        .containerBackgroundCreateuserSearch{
            position: relative;
            
            min-height:250px !important;
            width: 100%;
            
            background-repeat: no-repeat;
            background-position: top;
            
         }
         .containerBackgroundCreateuser{
            position: relative;
            background-position: center;
          
            
            width: 100%;
            height:100%;
       
            
            background-repeat: no-repeat;
          
            
         }
     
      .butonvideobannerfilter{
        font-weight:500  !important;
        
        max-width:15vmin !important;
        font-size:1.5vmin !important;
      } 
      .butonvideobannerfiltercerrar{
        font-weight:500  !important;
        
        max-width:10vmin !important;
        font-size:1.5vmin !important;
      }
      .butonvideobannerIndividual{
        font-weight:500  !important;
        margin-left:0 !important;
        padding: 2% 0vmin !important;
        max-width:15vmin !important;
        font-size:1.5vmin !important;
        margin-top:1vmin !important;
      }    
      .butonvideobanner_two{
        font-weight:500  !important;
        padding: 2% 0vmin !important;
        background:#ffff !important;
        border:1px solid #ffff !important;
        color:#CB3D82 !important;
        max-width:20vmin !important;
        font-size:1.5vmin !important;
      } 
      .clasebutton2{
        min-width:230px;
        margin-left:1vmin;
        margin-right:1vmin;
        background:#44596E;
        color:white;
        padding:1vmin 3vmin;
        border:1px solid #44596E;
        font-size:1.5vmin;
        font-weight:bold;
        border-top-left-radius:15px;
        border-top-right-radius:15px;
      }   
      #Nutri_button .cambio{
        margin-left:1vmin;
        margin-right:1vmin;
        background:#ffff;
        color:#CB3D82;
        padding:1vmin 3vmin;
        border:1px solid #ffff;
        font-size:1.5vmin;
        font-weight:bold;
        border-top-left-radius:15px;
        border-top-right-radius:15px;
      }  
        .banner_one form{
        
            display: flex;
            padding: 2px;
            border: 1px solid currentColor;
            border-radius: 5px;
        }
        .searchEjercicio button[type="submit"] {
            text-indent: -999px;
            overflow: hidden;
            width: 40px;
            padding: 0;
            margin: 0;
            border: 1px solid transparent;
            border-radius: inherit;
            background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
            cursor: pointer;
            opacity: 0.7;
          }

    }

    .tonin-prev:after,.tonin-next:after {
        font-size:15px !important;
        color:white;
            }
           .tonin-next{
            position:relative !important;
            background:#9EACED !important;
            margin:auto 5vmin auto 0;
            width:calc(2rem + 1vmin) !important;
            height:calc(2rem + 1vmin) !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
            }
            .tonin-prev{
                position:relative !important;
                background:#9EACED !important;
                margin:auto 1vmin;
                width:calc(2rem + 1vmin) !important;
                height:calc(2rem + 1vmin) !important;
                top: 0 !important;
                border-radius:50% !important;
                border:1px solid #9EACED;
            }
            .tonin-prev:after {
                content: 'prev';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                cursor: pointer;
                align-items: center;
            }
            .tonin-next:after {
                content: 'next';
                font-family: swiper-icons;
                font-size: 15px !important;
                font-variant: initial;
                height: 100%;
                cursor: pointer;
                justify-content: center;
                text-transform: none!important;
                letter-spacing: 0;
                display: flex;
                align-items: center;
            }
.contenedorFijo .title {
    text-align: center;
    color: #394F65;
    font-weight: bold;
    font-size: calc(5.0vmin + 0.2vw);
}

    
.contenedorFijo .titlepreguntas {
    text-align: left;
    margin-left:6.5%;
    margin-bottom:2vh;
    color: #394F65;
    font-weight: bold;
    font-size: calc(5.0vmin + 0.2vw);
}
.accordion-button::after {
    background-image: url("../../assets/newIcons/down-faqs.svg") !important;
    width: 1.25rem !important;
    height: 1.25rem !important;
    content: "";
    background-repeat: no-repeat;
    background-size: 1.25rem !important;
    margin-top:0.5vh;
    transition: transform .5s ease-in-out;
}
.accordion-button1::after {
    background-image: url("../../assets/assets/fechamuchas.png") !important;
    width: 1.25rem;
    height: 1.25rem;
    content:"";
    background-repeat: no-repeat;
    background-size: 1.25rem;
    margin-top:0.5vh;
    transition: transform .5s ease-in-out;
}


.accordion-button:not(.collapsed){
background:#fff !important;
box-shadow:none !important;
color:var(--elevvaTextTitle) !important;

}

.accordion-button:not(.collapsed)::after{

    transform: rotate(180deg) !important;
    }

input[type="search"] {
    background: URL("../../assets/img/search.png") no-repeat 5%;
    background-size: 1.5rem;
  
}





.input_search:focus {
    outline: none !important;
    border: 1px solid #2A9DA5;
    box-shadow: 0 0 10px #719ECE;
}


.CompletarFooter1 div {
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CompletarFooter1 .anterior1 {
    background-color:#262626;
    border-radius:25px;
    font-size:3vw;
    font-family:WorkSans-Bold
}





.letrasvideoarribaa {
  text-transform:capitalize;
}
.letrasvideoarribaa .cate {
    font-size: 0.8rem;
    color:#9DACBD;
  
}
.accordion .cate {
    font-size: 0.8rem;
    color:#9DACBD;  font-weight:500;
}

.glosario .accordion .accordion-item .accordion-header .accordion-button:focus {
    text-decoration: none;
}
.glosario .accordion .accordion-item .accordion-header .accordion-button1:focus {
    text-decoration: none;
}

#accordionGlosario .accordion-item{
    border:none !important
}

.terminos {
    color: #394F65;
    font-weight: bold;
    font-size: calc(1rem + 1vw);
}
#react-joyride-step-0 .react-joyride__tooltip{
   
    padding:0px 
}
.react-joyride__tooltip{
    border-radius:20px !important;
}

#react-joyride-step-0 .react-joyride__tooltip div:first-child{
  
    padding:0px !important
}
#react-joyride-step-0 .react-joyride__tooltip button{

    margin:20px !important
}
.react-joyride__tooltip button{
    border-radius:20px !important;
    padding:3% !important;
    
  }

  .verreservasVideos{
    font-size:1rem !important;
    font-weight: 500 !important;
    width: 100% !important;
  }
.pointerCompanie{
    cursor:pointer
}
.progressCompanie .bg-SOME_NAME{
    background-color: #CB3D82!important;
  }
  .elevation-3 {
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .05), 0 3px 4px 0 rgba(0, 0, 0, .05), 0 1px 7px 0 rgba(0, 0, 0, .05)
}


.swiper-button-prev-medicos:after,.swiper-button-next-medicos:after {
    font-size:15px !important;
    color:white;
        }
       .swiper-button-next-medicos{
        position:relative !important;
        background:#9EACED !important;
        margin:auto 5vmin auto 0;
        width:calc(2rem + 1vmin) !important;
        height:calc(2rem + 1vmin) !important;
        top: 0 !important;
        border-radius:50% !important;
        border:1px solid #9EACED;
        }
        .swiper-button-prev-medicos{
            position:relative !important;
            background:#9EACED !important;
            margin:auto 1vmin;
            width:calc(2rem + 1vmin) !important;
            height:calc(2rem + 1vmin) !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
        }
        .swiper-button-prev-medicos:after {
            content: 'prev';
            font-family: swiper-icons;
            font-size: 15px !important;
            font-variant: initial;
            height: 100%;
            justify-content: center;
            text-transform: none!important;
            letter-spacing: 0;
            display: flex;
            cursor: pointer;
            align-items: center;
        }
        .swiper-button-next-medicos:after {
            content: 'next';
            font-family: swiper-icons;
            font-size: 15px !important;
            font-variant: initial;
            height: 100%;
            cursor: pointer;
            justify-content: center;
            text-transform: none!important;
            letter-spacing: 0;
            display: flex;
            align-items: center;
        }

        .swiper-button-prev-pindos:after,.swiper-button-next-pindos:after {
            font-size:15px !important;
            color:white;
                }
               .swiper-button-next-pindos{
                position:relative !important;
                background:#9EACED !important;
                margin:auto 5vmin auto 0;
                width:calc(2rem + 1vmin) !important;
                height:calc(2rem + 1vmin) !important;
                top: 0 !important;
                border-radius:50% !important;
                border:1px solid #9EACED;
                }
                .swiper-button-prev-pindos{
                    position:relative !important;
                    background:#9EACED !important;
                    margin:auto 1vmin;
                    width:calc(2rem + 1vmin) !important;
                    height:calc(2rem + 1vmin) !important;
                    top: 0 !important;
                    border-radius:50% !important;
                    border:1px solid #9EACED;
                }
                .swiper-button-prev-pindos:after {
                    content: 'prev';
                    font-family: swiper-icons;
                    font-size: 15px !important;
                    font-variant: initial;
                    height: 100%;
                    justify-content: center;
                    text-transform: none!important;
                    letter-spacing: 0;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                }
                .swiper-button-next-pindos:after {
                    content: 'next';
                    font-family: swiper-icons;
                    font-size: 15px !important;
                    font-variant: initial;
                    height: 100%;
                    cursor: pointer;
                    justify-content: center;
                    text-transform: none!important;
                    letter-spacing: 0;
                    display: flex;
                    align-items: center;
                }

                .swiper-button-prev-numbers:after,.swiper-button-next-numbers:after {
                    font-size:15px !important;
                    color:white;
                        }
                       .swiper-button-next-numbers{
                        position:relative !important;
                        background:#9EACED !important;
                        margin:auto 5vmin auto 0;
                        width:calc(2rem + 1vmin) !important;
                        height:calc(2rem + 1vmin) !important;
                        top: 0 !important;
                        
                        border-radius:50% !important;
                        border:1px solid #9EACED;
                        }
                        .swiper-button-prev-numbers{
                            position:relative !important;
                            background:#9EACED !important;
                            margin:auto 1vmin;
                            width:calc(2rem + 1vmin) !important;
                            height:calc(2rem + 1vmin) !important;
                            top: 0 !important;
                            border-radius:50% !important;
                            border:1px solid #9EACED;
                        }
                        .swiper-button-prev-numbers:after {
                            content: 'prev';
                            font-family: swiper-icons;
                            font-size: 15px !important;
                            font-variant: initial;
                            height: 100%;
                            justify-content: center;
                            text-transform: none!important;
                            letter-spacing: 0;
                            display: flex;
                            cursor: pointer;
                            align-items: center;
                        }
                        .swiper-button-next-numbers:after {
                            content: 'next';
                            font-family: swiper-icons;
                            font-size: 15px !important;
                            font-variant: initial;
                            height: 100%;
                            cursor: pointer;
                            justify-content: center;
                            text-transform: none!important;
                            letter-spacing: 0;
                            display: flex;
                            align-items: center;
                        }


                        .swiper-button-prev-months:after,.swiper-button-next-months:after {
                            font-size:15px !important;
                            color:white;
                                }
                               .swiper-button-next-months{
                                position:relative !important;
                                background:#9EACED !important;
                                margin:auto 5vmin auto 0;
                                width:calc(1.5rem + 1vmin) !important;
                                height:calc(1.5rem + 1vmin) !important;
                                top: 0 !important;
                                
                                border-radius:50% !important;
                                border:1px solid #9EACED;
                                }
                                .swiper-button-prev-months{
                                    position:relative !important;
                                    background:#9EACED !important;
                                    margin:auto 1vmin;
                                    width:calc(1.5rem + 1vmin) !important;
                                    height:calc(1.5rem + 1vmin) !important;
                                    top: 0 !important;
                                    border-radius:50% !important;
                                    border:1px solid #9EACED;
                                }
                                .swiper-button-prev-months:after {
                                    content: 'prev';
                                    font-family: swiper-icons;
                                    font-size: 10px !important;
                                    font-variant: initial;
                                    height: 100%;
                                    justify-content: center;
                                    text-transform: none!important;
                                    letter-spacing: 0;
                                    display: flex;
                                    cursor: pointer;
                                    align-items: center;
                                }
                                .swiper-button-next-months:after {
                                    content: 'next';
                                    font-family: swiper-icons;
                                    font-size: 10px !important;
                                    font-variant: initial;
                                    height: 100%;
                                    cursor: pointer;
                                    justify-content: center;
                                    text-transform: none!important;
                                    letter-spacing: 0;
                                    display: flex;
                                    align-items: center;
                                }




.swiper-button-prev-comidas:after,.swiper-button-next-comidas:after {
    font-size:15px !important;
    color:white;
        }
       .swiper-button-next-comidas{
        position:relative !important;
        background:#9EACED !important;
        margin:auto 5vmin auto 0;
        width:calc(2rem + 1vmin) !important;
        height:calc(2rem + 1vmin) !important;
        top: 0 !important;
        border-radius:50% !important;
        border:1px solid #9EACED;
        }
        .swiper-button-prev-comidas{
            position:relative !important;
            background:#9EACED !important;
            margin:auto 1vmin;
            width:calc(2rem + 1vmin) !important;
            height:calc(2rem + 1vmin) !important;
            top: 0 !important;
            border-radius:50% !important;
            border:1px solid #9EACED;
        }
        .swiper-button-prev-comidas:after {
            content: 'prev';
            font-family: swiper-icons;
            font-size: 15px !important;
            font-variant: initial;
            height: 100%;
            justify-content: center;
            text-transform: none!important;
            letter-spacing: 0;
            display: flex;
            cursor: pointer;
            align-items: center;
        }
        .swiper-button-next-comidas:after {
            content: 'next';
            font-family: swiper-icons;
            font-size: 15px !important;
            font-variant: initial;
            height: 100%;
            cursor: pointer;
            justify-content: center;
            text-transform: none!important;
            letter-spacing: 0;
            display: flex;
            align-items: center;
        }
        .playervideo video {
            border-radius:20px;
        }
      
        

    /* border: 1px solid #e9f3f7; */