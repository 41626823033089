.react-calendar {
    border: none !important;;
}
.react-calendar__navigation {
    /* border: 1px solid white; */
    border-radius: 20px;
    background-color: #f1f6f9;
}
.react-calendar__tile--now{
    border: 1px solid #cb3d82 !important;
    border-radius: 20px !important;
    background: none !important;
}

.react-calendar__tile--active{
    color: black !important;
}

abbr[title]{
    text-decoration: none !important;
    cursor: auto !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none !important;
}

.react-calendar__month-view__days__day--weekend{
    color: #cb3d82;
}