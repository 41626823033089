.containerProgress_one .progress-bar {
background-color:#E16250 !important ;
font-size:0.9rem !important;border-radius:20px;

}

.addColorSelected{
  filter: sepia(0.2) saturate(100) brightness(35%) contrast(87%) hue-rotate(167deg) invert(34%);
-webkit-filter: sepia(0.2) saturate(100) brightness(35%) contrast(87%) hue-rotate(167deg) invert(34%);
}

.active_one_progress .progress-bar{
  color:#E16250 !important
}
.active_two_progress .progress-bar{
  color:#FFB6A4 !important
}
.active_three_progress .progress-bar{
  color:#F5D7CF !important
}
.active_four_progress .progress-bar{
  color:#7988F6 !important
}
.active_five_progress .progress-bar{
  color:#6EE1FB  !important
}

@media (min-width:1650px) {
  .rv-xy-plot__axis__tick__text {
    
    font-size: 0.8rem !important;
  
}
  .containerProgress_one .progress, .containerProgress_two .progress, .containerProgress_three .progress,.containerProgress_four .progress,.containerProgress_five .progress {
    height: 100% !important;
  }
  .containerProgress_one, .containerProgress_two, .containerProgress_three,.containerProgress_four,.containerProgress_five {
    margin: 0.25rem 0 !important;
  }
  .button-share{
    max-width:inherit;
    padding:10px
    }
 
}
@media (min-width:992px) {
  .results-image-icon{
    background: #9EACED;
    border-radius: 50%;
    padding:3%;
    align-items:center;
    /* width: calc(3rem + 0.3vw);
    height: calc(3rem + 0.3vw); */
  }
  .results-icon-label-u{
    color: #394F65;
     font-size: calc(0.7rem + 0.4vw)
  }
  .results-icon-label-d{
    color: #A9B6C4;
     font-size: calc(0.6rem + 0.4vw)
  }
  .image-results-promedios{
    width: 6vmin;
    height: 6vmin;
  }
}
@media (max-width:991px) {
  .all_bar .progress,.mobileProgressHei .progress{
    height:1.3rem
  }
  .letraPequeResults{
    font-size:0.7rem !important
  }
  .image-results-promedios{
    width:100%;
    height:100%;
    max-width: 40px;
    max-height: 40px;
  }
  .results-image-icon{
    background: #9EACED;
    border-radius: 50%;
    align-items:center;
    width: calc(5rem + 0.3vw);
    height: calc(5rem + 0.3vw);
  }

  .results-icon-label-u{
    color: #394F65;
     font-size: calc(1rem)
  }
  .results-icon-label-d{
    color: #A9B6C4;
     font-size: calc(1rem)
  }
}

@media (max-width:1649px) {
  .button-share{
    max-width:inherit;
    padding:5px
    }
  .containerProgress_one, .containerProgress_two, .containerProgress_three,.containerProgress_four,.containerProgress_five {
    font-size: 0.9rem !important;
  }
  .containerProgress_one, .containerProgress_two, .containerProgress_three,.containerProgress_four,.containerProgress_five {
    margin: 0.10rem 0 !important;
  }
  .button-share{
    font-size:calc(0.7rem + 0.1vmin) !important
  }
}


.containerProgress_two .progress-bar {
background-color:#FFB6A4 !important;
font-size:0.9rem !important;
border-radius:20px;
height:100% !important

}
.containerProgress_three .progress-bar {
  background-color:#F5D7CF !important ;
  font-size:0.9rem !important;border-radius:20px;height:100% !important

  }
  .containerProgress_four .progress-bar {
    background-color:#7988F6 !important;
    font-size:0.9rem !important ;
    border-radius:20px;
    height:100% !important
    }

    .containerProgress_five .progress-bar {
      background-color: #6EE1FB !important ;
      font-size:0.9rem !important;
      border-radius:20px;
      height:100% !important
      }
      /* .roma_web div:first-child{
        display:flex;
        justify-content: center;
        flex-direction:column;
      } */
      .altura_change_sesiones{
        height:90%
      }
 