.imagen-LoginCustomer-WelcomePost{
   
    background-repeat:no-repeat;
     background-size:cover;
     border-top-right-radius: 30px;
     border-bottom-right-radius: 30px;
     background-position: center;
 
 }
 .colorIconMessage{
  color: #cb3d82
 }

 @media (min-width: 767px) {


  .panel-welcome-responsive {
    padding: 7% 0;
    text-align: left;
   
   }

 }

 @media (max-width: 766px) {
.border_bottom_welcome{
  border-bottom-left-radius:20px;
  border-bottom-right-radius:20px;
}

  .panel-welcome-responsive {
    padding: 7% calc(100vw - 83%) !important;
    text-align: left
   }
 }

 @media (max-width: 992px)  {
  #navTerminos{
    font-size:0.8rem !important
  }
  #navTerminos a u{
    font-size:0.8rem !important
  }
.novoHeightMem{
  height:100%;
}
.novoHeightMemPaso2{
  height:65% !important;
}

.crecerMiMember{
  width:18vmin !important;
  height:18vmin !important;
}

.mobileMembers{
  height: 100%;
}

  .primer-columna{
    display: none;
  }

  .inside-content-full-Members{
    background-color:var(--white05);
    width: 100%;
    height:100%;
    position:absolute;
   
    background-color: #ffff;
    z-index: 1;
  
   
    /* border:1px solid blue; */
    
  }

  .primer-columna-dos {
    background-color:transparent;
    /* border:1px solid pink; */
    width: 100%;
    height:100%;
   
    z-index: 1;
  
  
    border-radius:20px;
    justify-content: center;
  }

  .CompletarFooterMembers {
    position: fixed !important;
    background-color: transparent;
    z-index: 2;
    top: 93% !important;
    left: 50%;
    transform: translate(-50%, -93%);
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 100%;
    color: white;
    padding: 0;
    margin: auto;
    border: 00 !important;
    cursor: pointer;
    
}

.other_nav{ text-align:center;
  position: fixed !important;
  background-color: transparent;
  z-index: 2;
  top: 93% !important;
  left: 50%;
  transform: translate(-50%, -93%);
  justify-content: center;
  align-items: center;
  display: inline-flex;
  width: 90%;
  color: white;
  padding: 0;
  margin: auto;
  border: 00 !important;
  cursor: pointer;
}
.fondoImgenMobile{
  width:90%;
  margin:3% auto;
  background-position:center;
  height:20%;
  border-radius:20px;
  background-size:cover;
  background-image:url("../../../assets/assets/onboardingMobileone.png");
  background-repeat:no-repeat;

}

.text-color-title-members{
  font-weight:300;
  color:var(--elevvaTexttitleLow);
}

 }


 @media (min-width: 993px)  {
  .novoHeightMem{
    height:100%;
  }
  .novoHeightMemPaso2{
    height:100%;
    display:flex
  }

  .textoMemberCircle{
    font-size:calc(0.4rem + 0.5vw)
  }
  .text-color-title-members{
  
      font-weight:300;
      color:var(--elevvaTexttitleLow);
      white-space: nowrap; 
  
  }

  .fondoImgenMobile{
    display:none
  }
  .other_nav{
    position: fixed !important;
    background-color: transparent;
    z-index: 2;
    top: 97% !important;
    left: 60%;
    transform: translate(-50%, -100%);
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 90vmin;
    color: white;
    padding: 0;
    margin: auto;
    border: 00 !important;
    cursor: pointer;
  }


  .CompletarFooterMembers {
    position: fixed !important;
    background-color: transparent;
    z-index: 2;
    top: 95% !important;
    left: 50%;
    transform: translate(-50%, -95%);
    justify-content: center;
    align-items: center;
    display: inline-flex;
    width: 45vmin;
    color: white;
    padding: 0;
    margin: auto;
    border: 00 !important;
    cursor: pointer;
}


  .primer-columna-dos {
    background-color:transparent;
    /* border:1px solid pink; */
    width: 70%;
    height:88%;
   
    z-index: 1;
  
  
    border-radius:20px;
    justify-content: center;
  }


  .mobileMembers{
    width: 90%;
  }

.bulletsPositionMembersh{
  align-items:center;
  bottom:'60%';
}

.colorBackgroundMem{
  height:100vh;
  background:var(--backgroundOnboardingContain);
}

.inside-content-full-Members{
  background-color:var(--white05);
  width: 95%;
  height:88%;
  position:absolute;
  top: 50%;
  background-size:contain;
   background-image:url("../../../assets/assets/logooutback.png");
   background-repeat:no-repeat;
   background-position:right;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffff;
  z-index: 1;
  gap:2.5%;
  padding:1%;
  /* border:1px solid blue; */
  border-radius:20px;
}

  .primer-columna{
    background-color:var(--white05);
   
    width: 20%;
    height:88%;
    z-index: 1;
    border-radius:20px;
    justify-content: center;
  }
 }


 .panel-welcome-responsive {
  padding: 7% 0;
  text-align: left
 }
 .ubication-welcome-post{
    padding:15% 10%;
 }
 .textlargenut{
    white-space:nowrap
 }

 
 /*  modal */
 .modal-header {
   border-bottom: none !important;
 }

 .modal-footer {
   border-top: none !important;
  
 }

 .btn:hover {
   background-color: #cb3d82;
   border-color: #cb3d82;
   cursor: pointer;
 }

 .btn {
   border-radius: 32px;
 }

 .modal-header .btn-close {
  border-radius: 20px;
  border: 1px #9eaced solid
 }


.modal-content {
  border-radius: 23px;
}

.modalcitoOk {
  color: rgb(255, 255, 255);
font-size: 2rem;
border-radius: 1px solid;
border: 1px #fff solid;
border-radius: 50%;
width: 50px;
height: 50px;
text-align: center;
background-color: #9eaced;
}

/* nuevostemas css */
.contain-info-onboardingMember{
  
  width:85%;
  height:82%;
  border:1px solid black;
  z-index: 2;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius:20px;
  justify-content: center;
}





.primer-fila{
  background-color:transparent;
 
  width: 100%;

  z-index: 1;
  border-radius:20px;
  justify-content: center;
}
.primer-fila-dos {
  background-color:transparent;
  /* border:1px solid pink; */
  width: 100%;
  
 
  z-index: 1;


  border-radius:20px;
  justify-content: center;
}
.h1-with-line {
  display: flex;
  width: auto;
  
  vertical-align:middle;
}
.h1-with-line::after {
  display: inline-block;
    content: "";
    border-top: 1px solid #E5E9ED;
    width: 50%;
    margin:auto;
   

}

.swipersepMembers{
  margin:auto;
  position:relative;
    text-align:center;
     border-left: 2px solid var(--elevvaLineOnboarding);
     height:30px;
     
 }

