.backgrounImgLoginStart{
  
    right: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
