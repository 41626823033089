.nueva_reserva{
padding:4px;
}

@media (min-width:992px) {
  .altura_change_clases{
    height:60%;
  }
  .text_hour_misclases{
    font-size: calc(0.6rem + 0.2vw)
   }
}
@media (min-width:1350px) {
  .altura_change_clases{
    height:75%;
  }
}

@media (max-width:992px) {
  #tutorialButtonReservaMobile{
    width:40%
  }
  .text_hour_misclases{
   font-size: calc(0.9rem)
  }
}
.widthYesNo{
  max-width: 100%
}